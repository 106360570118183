import {legacy_createStore} from "redux";
import IGReducer from "./Reducers";
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage' // defaults to localStorage for web


const persistConfig = {
    key: 'root',
    storage,
  }

const persistedReducer = persistReducer(persistConfig, IGReducer)
const store = legacy_createStore(persistedReducer);
export const persist = persistStore(store)

export default store;