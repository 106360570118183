import { Avatar } from "@mui/material";
import { FaCamera } from "@react-icons/all-files/fa/FaCamera";
import { IoWalletSharp } from "@react-icons/all-files/io5/IoWalletSharp";
import dayjs from "dayjs";
import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { ToastContainer, toast } from "react-toastify";
import store from "../Redux/Store";
import FetchApi from "../config/ApiCal";
import { imageUrl } from "../config/Const";
import { LoginString } from "../config/LoginString";
import { images } from "../images";
import "../styles/profile.css"; //new class style maintained in this file remain class are as same as  login.css file
const Profile = () => {
  const LoginData = useSelector((state) => state.LgnData);
  const navigate = useNavigate();
  const [state, setState] = useState({
    first_name: LoginData.first_name ? LoginData.first_name : "",
    last_name: LoginData.last_name ? LoginData.last_name : "",
    email: LoginData.email ? LoginData.email : "",
    phone_number: LoginData.phone_number ? LoginData.phone_number : "",
    country_code: LoginData.country_code ? LoginData.country_code : "",
    password: LoginData.password ? LoginData.password : "",
  });

  console.log(LoginData)
  const [err, setErr] = useState({
    firstNameErr: "",
    lastNameErr: "",
    passwordErr: "",
    // phoneNumberErr: "",
  });

  const hiddenFileInput = useRef(null);
  const [selectedImage, setSelectedImage] = useState(
    LoginData.avatar ? LoginData.avatar : null
  );
  const [btnLoad, setBtnLoad] = useState(false);
  const [planStatus, setPlanStatus] = useState([]);
  const handleImageUpload = (event) => {
    //trigger the hidden field input
    hiddenFileInput.current.click();
  };

  const handleFileChange = (event) => {
    const input = event.target;
    if (input.files && input.files[0]) {
      var reader = new FileReader();
      reader.onload = async function (e) {
        var splitted = e.target.result.split(",");
        var splitd = splitted[1];
        FetchApi("avatarupload", { id: LoginData.id, image: splitd })
          .then((data) => {
            if (data.message == "Success") {
              setSelectedImage(data.result.avatar);
              const updateData = {
                avatar: data.result.avatar,
              };
              store.dispatch({ type: "updateUserImage", payload: updateData });
              toast.success("Image uploaded Successfully", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
              });
            } else {
              toast.error(data.message, {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
              });
            }
          })
          .catch((err) => console.log(err));
      };
      reader.readAsDataURL(input.files[0]);
    }
  };

  const updateValidation = () => {
    let isValid = true;
    let firstNameErr = "";
    let lastNameErr = "";
    let passwordErr = '';
    // let phoneNumberErr = '';

    if (state.first_name == "") {
      firstNameErr = "Enter First Name";
      isValid = false;
    } else {
      firstNameErr = "";
    }

    if (state.last_name == "") {
      lastNameErr = "Enter Last Name";
      isValid = false;
    } else {
      lastNameErr = "";
    }

    if (state.password == "") {
      passwordErr = "Password is required"
      isValid = false;
    }
    else {
      passwordErr = "";
    }
    if (state.password.length < 8) {
      passwordErr = "password must min 8 character"
      isValid = false;
    }
    else {
      passwordErr = "";
    }

    // if (state.email != "") {
    //     let lastAtPos = state.email.lastIndexOf("@");
    //     let lastDotPos = state.email.lastIndexOf(".");
    //     if (
    //         !(
    //             lastAtPos < lastDotPos &&
    //             lastAtPos > 0 &&
    //             state.email.indexOf("@@") == -1 &&
    //             lastDotPos > 2
    //         )
    //     ) {
    //         isValid = false;
    //         emailErr = "Enter Valid Email"
    //     }
    // }

    // if (state.phone_number == "") {
    //     phoneNumberErr = "Enter Phone Number"
    //     isValid = false;
    // }
    // else {
    //     phoneNumberErr = "";
    // }

    if (!isValid) {
      setErr({
        firstNameErr: firstNameErr,
        lastNameErr: lastNameErr,
        passwordErr,
        // phoneNumberErr: phoneNumberErr
      });
    } else {
      setErr({
        firstNameErr: "",
        lastNameErr: "",
        // emailErr: '',
        // phoneNumberErr: ''
      });
    }

    return isValid;
  };

  const handleSubmit = (e) => {
    toast.dismiss();
    console.log(e);
    setBtnLoad(true);
    e.preventDefault();
    if (updateValidation()) {
      let params = {
        id: localStorage.getItem("user_id"),
        first_name: state.first_name,
        last_name: state.last_name,
        password: state.password,
      };

      FetchApi("updateprofile", params).then((data) => {
        if (data.message == "Customer updated successfully.") {
          toast.success("Profile Updated Successfully", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });

          const updateData = {
            first_name: data.data.first_name,
            last_name: data.data.last_name,
            password: data.data.password
          };
          store.dispatch({ type: "updateUserData", payload: updateData });
          setBtnLoad(false);
        } else {
          toast.error("Failed", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
          setBtnLoad(false);
        }
      });
    } else {
      setBtnLoad(false);
    }
  };
  useEffect(() => {
    FetchApi('planDetail', {
      customer_id: LoginData.id
    }).then(e => {

      setPlanStatus(e.result)
      console.log(e.result, 'red')
    })
  }, []);
  return (
    <div className="Login-Section">
      <div className="d-flex  justify-content-center">
        <div className="Login-title-txt text-center">
          <span className="Login-frtext">{LoginString.ProfileTitle}</span>{" "}
          <span className="Login-sectext">
            {LoginData.id ? LoginData.first_name : ""}
          </span>
          <div>
            <span className="subtitle-text">{LoginString.ProfileDes}</span>
          </div>
        </div>
      </div>
      <div className="container profile_container">
        <div className="row d-flex text-align-center justify-content-center">
          <div className="col-lg-5">
            <div className="LoginBox">
              <form onSubmit={handleSubmit}>
                <div className="Input-Box-Profile-Section pt-3 pb-2">
                  {
                    planStatus.length === undefined ?

                      <div className="avatar-sec ">
                        <Avatar
                          alt={LoginData.id ? LoginData.first_name : ""}
                          src={
                            selectedImage ? imageUrl + selectedImage : images.user
                          }
                          className="prime-member"
                          sx={{ width: 100, height: 100 }}
                        />
                        <span>
                          <svg className="fs-1 verifyed" stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 24 24" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg" data-darkreader-inline-fill="" data-darkreader-inline-stroke=""><path d="M10.007 2.10377C8.60544 1.65006 7.08181 2.28116 6.41156 3.59306L5.60578 5.17023C5.51004 5.35763 5.35763 5.51004 5.17023 5.60578L3.59306 6.41156C2.28116 7.08181 1.65006 8.60544 2.10377 10.007L2.64923 11.692C2.71404 11.8922 2.71404 12.1078 2.64923 12.308L2.10377 13.993C1.65006 15.3946 2.28116 16.9182 3.59306 17.5885L5.17023 18.3942C5.35763 18.49 5.51004 18.6424 5.60578 18.8298L6.41156 20.407C7.08181 21.7189 8.60544 22.35 10.007 21.8963L11.692 21.3508C11.8922 21.286 12.1078 21.286 12.308 21.3508L13.993 21.8963C15.3946 22.35 16.9182 21.7189 17.5885 20.407L18.3942 18.8298C18.49 18.6424 18.6424 18.49 18.8298 18.3942L20.407 17.5885C21.7189 16.9182 22.35 15.3946 21.8963 13.993L21.3508 12.308C21.286 12.1078 21.286 11.8922 21.3508 11.692L21.8963 10.007C22.35 8.60544 21.7189 7.08181 20.407 6.41156L18.8298 5.60578C18.6424 5.51004 18.49 5.35763 18.3942 5.17023L17.5885 3.59306C16.9182 2.28116 15.3946 1.65006 13.993 2.10377L12.308 2.64923C12.1078 2.71403 11.8922 2.71404 11.692 2.64923L10.007 2.10377ZM6.75977 11.7573L8.17399 10.343L11.0024 13.1715L16.6593 7.51465L18.0735 8.92886L11.0024 15.9999L6.75977 11.7573Z"></path></svg>
                        </span>

                        <div className="prof-camera" style={{ cursor: "pointer" }}>
                          <FaCamera role="button" onClick={handleImageUpload} />


                          <input
                            hidden
                            className="file-upload"
                            type="file"
                            name="avatar"
                            onChange={handleFileChange}
                            ref={hiddenFileInput}
                            accept="image/*"
                          />
                        </div>
                      </div> : <div className="avatar-sec ">
                        <Avatar
                          alt={LoginData.id ? LoginData.first_name : ""}
                          src={
                            selectedImage ? imageUrl + selectedImage : images.user
                          }
                          className=""
                          sx={{ width: 100, height: 100 }}
                        />

                        <div className="prof-camera" style={{ cursor: "pointer" }}>

                          <FaCamera role="button" onClick={handleImageUpload} />
                          <input
                            hidden
                            className="file-upload"
                            type="file"
                            name="avatar"
                            onChange={handleFileChange}
                            ref={hiddenFileInput}
                            accept="image/*"
                          />
                        </div>
                      </div>

                  }


                  <div className="lable-section">
                    <div className="mt-5 border-bottom">
                      <div className="text-muted border-bottom fw-bold d-flex justify-content-between align-items-center"><p>Subscription</p>

                        {planStatus.length === undefined ? <button type="button" onClick={() => navigate('/subscriptionHistory')} className="border-0 rounded-2 btn-sm bg-app-color text-white">Manage</button> : <button type="button" onClick={() => navigate('/subscriptionPlans')} className="border-0 rounded-2 btn-sm bg-app-color text-white">Upgrade</button>

                        }


                      </div>
                      {
                        planStatus.length === undefined ? <div className="d-flex justify-content-between align-items-center p-2">
                          <div>

                            <p className="m-0">{planStatus?.name} </p>
                            <p className="text-muted" style={{ fontSize: 12 }}>{planStatus?.sub_description} </p>

                            <p className="text-muted m-0" style={{ fontSize: 12 }}>Exp: {dayjs(planStatus?.subscription_expiry).format('DD-MM-YYYY')} </p>
                          </div>
                          <div className='align-self-start'> ${planStatus?.amount} GPB</div>

                        </div> :
                          <div className="text-center py-3 text-muted">
                            ---- No Active plans ----
                          </div>
                      }



                    </div>
                    <div className="form-group grp-1">
                      <label for="InputFirstName1" className="form-labels">
                        {LoginString.RegisterFNLabel}
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="InputFirstName1"
                        aria-describedby="NameHelp"
                        placeholder="First name"
                        value={state.first_name}
                        onChange={(e) =>
                          setState({
                            ...state,
                            first_name: e.target.value,
                          })
                        }
                      />
                      <span className="err-txt">
                        {err.firstNameErr ? err.firstNameErr : ""}
                      </span>
                    </div>
                    <div className="form-group grp-1">
                      <label for="InputLasttName1" className="form-labels">
                        {LoginString.RegisterLNLabel}
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="InputLasttName1"
                        aria-describedby="LNameHelp"
                        placeholder="Last name"
                        value={state.last_name}
                        onChange={(e) =>
                          setState({
                            ...state,
                            last_name: e.target.value,
                          })
                        }
                      />
                      <span className="err-txt">
                        {err.lastNameErr ? err.lastNameErr : ""}
                      </span>
                    </div>
                    <div className="form-group grp-3">
                      <label for="exampleInputEmail1" className="form-labels">
                        {LoginString.Label1}
                      </label>
                      <input
                        type="email"
                        className="form-control text-muted"
                        id="exampleInputEmail1"
                        aria-describedby="emailHelp"
                        placeholder="Email"
                        value={state.email}
                        readOnly
                      />
                      {/* <span
                                                className="err-txt"
                                            >
                                                {err.emailErr ? err.emailErr : ""}
                                            </span> */}
                    </div>

                    {/* <div className='row'> */}

                    {/* <div className='col-3'>
                                            <div className="form-group grp-1" id='countryInput'>
                                                <input type="number" className="form-control" id="examplecountrycode" aria-describedby="emailHelp" placeholder="CC"/>
                                            </div>
                                        </div> */}
                    {/* <div className='col-12'> */}

                    <div className="form-group grp-1" id="countryInput" style={{ cursor: 'default !important' }}>
                      <label className="form-labels">
                        Password
                      </label>
                      <input
                        autoComplete="OFF"
                        type="password"
                        className="form-control text-muted"
                        placeholder="Password"
                        value={state.password}
                        onChange={(e) =>
                          setState({
                            ...state,
                            password: e.target.value,
                          })
                        }

                      />
                      <span
                        className="err-txt"
                      >
                        {err.passwordErr ? err.passwordErr : ""}
                      </span>
                    </div>

                    <div className="form-group grp-3" id="countryInput">
                      <label className="form-labels">
                        {LoginString.RegisterMNLabel}
                      </label>
                      <input
                        type="text"
                        className="form-control text-muted"
                        placeholder="Mobile Number"
                        value={state.country_code + " " + state.phone_number}
                        readOnly
                      />
                      {/* <span
                                                        className="err-txt"
                                                    >
                                                        {err.phoneNumberErr ? err.phoneNumberErr : ""}
                                                    </span> */}
                    </div>
                    {/* </div> */}
                    {/* </div> */}
                  </div>

                </div>
              </form>
              <div className="Login-Buttons mb-1 text-center pb-4 pt-5  justify-content-center align-items-center gap-2 d-md-flex d-block d-lg-flex ">
                {/* <button
                  className="lgn-btn-down bg-base-color border-0"
                  type="button"
                  onClick={() => navigate("/manageDocument")}
                >
                  <SiGoogledocs size={15} />     {LoginString.manage}
                </button> */}
                {/* <div className="Login-Buttons  pb-3 text-center"> */}
                <button
                  className="lgn-btn bg-info border-0 mt-2 mt-md-0 mt-lg-0 "
                  type="button"
                  onClick={() => navigate("/wallet")}
                >
                  <IoWalletSharp size={15} />     {LoginString.wallet}
                </button>
                <button className="lgn-btn" onClick={handleSubmit} disabled={btnLoad}>
                  {btnLoad ? (
                    <small className="spinner-border spinner-border-sm"></small>
                  ) : (
                    LoginString.ProfileUpte
                  )}
                </button>
                {/* </div> */}

              </div>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};

export default Profile;
