import { PayPalButtons, PayPalScriptProvider } from "@paypal/react-paypal-js";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js/pure";
import { useState } from "react";
import { toast } from "react-toastify";
import { PAYPAL_CLIENT_ID, STRIPE_CLIENT_ID } from "../config/Const";
import { images } from "../config/ConstImg";
import "../styles/PaymentScreen.css";
import CheckoutForm from "./CheckoutForm";

// Stripe.js will not be loaded until `loadStripe` is called
// const stripe = await loadStripe(STRIPE_CLIENT_ID);

export default function Paypal({
  type,
  getToken = null,
  amount = 0,
  symbol = "",
  click = null,
  overlay = "static",
}) {
  const [stripePromise, setStripePromise] = useState(() =>
    loadStripe(STRIPE_CLIENT_ID)
  );

  return (
    <div className="container ">
      <div className="">
        {type == "paypal" ? (
          <PayPalScriptProvider
            options={{
              clientId: PAYPAL_CLIENT_ID,
              currency: "USD",
              "disable-funding":
                "credit,card,paylater,giropay,sepa,sofort,venmo",
            }}
          >
            <PayPalButtons
              createOrder={(data, actions) => {
                return actions.order.create({
                  purchase_units: [
                    {
                      amount: {
                        value: amount, // Change this to the amount you want to charge
                      },
                    },
                  ],
                });
              }}
              onApprove={(data, actions) => {
                getToken(data);
                // return actions.order.capture().then(function (details) {
                console.log(data, "data");
                if (data.paymentID) {
                  toast.success("Payment Authorized Successfully", {
                    position: "top-right",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                  });
                }
                // });
              }}
              style={{
                layout: "vertical",
                color: "white",
                shape: "pill",
                label: "paypal",
                height: 40,
              }}
              onCancel={(data) => {
                toast.error("Payment declined", {
                  position: "top-right",
                  autoClose: 2000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                  theme: "light",
                });
              }}
            />
          </PayPalScriptProvider>
        ) : (
          <>
            <div
              className="modal fade"
              id="card-save"
              data-bs-backdrop="static"
              tabIndex="-1"
              aria-labelledby="staticBackdropLabel"
              aria-hidden="true"
            >
              <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                  <div className="modal-header logout-header">
                    <h1 className="modal-title fs-5" id="staticBackdropLabel">
                      Pay With Stripe
                    </h1>
                    <button
                      type="button"
                      className="btn-close logout-close-btn"
                      data-bs-dismiss="modal"
                      id="card-close"
                    ></button>
                  </div>
                  <div className="modal-body">
                    <Elements stripe={stripePromise}>
                      <CheckoutForm
                        amount={amount}
                        setToken={getToken}
                        symbol={symbol}
                      />
                    </Elements>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="add_card "
              data-bs-toggle="modal"
              onClick={click}
              data-bs-target="#card-save"
            >
              <img
                src={images.stripe}
                alt="stripe-img"
                className="payment-stripe-img"
              />
              <span>PAY NOW WITH STRIPE</span>
            </div>
          </>
        )}
      </div>
    </div>
  );
}
