import { CircularProgress } from "@mui/material";
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import RevoCalendar from "revo-calendar";
import FetchApi from "../config/ApiCal";
// import {LocaleConfig} from 'react-native-calendars';
function EventCalender({ type = 1 }) {
  const [loading, setLoader] = useState(true);
  const currentUrl = useParams();
  // console.log(currentUrl, "pres");
  const [events, setEvents] = useState([]);
  const LoginData = useSelector((state) => state.LgnData);
  const [date, setdate] = useState("");
  const navigate = useNavigate();
  useEffect(() => {

    if (type == 1) {
      const dates = btoa(dayjs().format('DD-MM-YYYY'));

      console.log(dayjs().format('DD-MM-YYYY'));
      console.log(atob(currentUrl?.token), 'data');

      if (isValidRequest()) {

        calenderJobs();
        // toast.success("show");
      } else {
        // navigate('/')
        toast.error("Something went Wrong");
      }
    } else {
      calenderJobs();
    }

    // $(".sc-fqkvVR").click();
  }, []);
  function isWebView() {
    var userAgent = navigator.userAgent;

    const check = /(android)|(iphone)|(ipad)/i.test(userAgent);
    console.log(check);

    return check;
  }

  function isValidRequest() {
    return atob(currentUrl?.token) == dayjs().format('DD-MM-YYYY').toString();
  }

  const calenderJobs = async () => {
    const apiReqest = await FetchApi("CalendarJobs", {
      mode: type == 1 ? currentUrl?.type : "customer",
      user_id: type == 1 ? currentUrl?.id : LoginData.id,
    }).then((e) => {
      setEvents(e.data.map((d)=> ({...d,date:dayjs(d.date).format("DD/MM/YYYY HH:mm")})));
      setLoader(false);
      // console.log(e, "res");
    });
  };
  console.log(events,'ud');
  const dateChange = (day, mon, y) => {
    const dayw = day + "/" + dayjs().month(mon).format("MMM") + "/" + y;
    // console.log(dayw, "ufsdgfv");
    setdate(dayjs(dayw).format("DD/MM/YYYY"));
  };

  const eventss = [
    {
      name: "Booking ID: #659-Start",
      date: dayjs("11/05/2024 10:28").format("DD/MM/YYYY HH:mm")
    }
  ];
  return (
    <div
      className="postion-relative d-flex justify-content-center align-items-center"
      style={{ minHeight: type == 1 ? "100vh" : "60vh", position: "relative" }}
    >
      <ToastContainer />
      {loading ? (
        <div
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            bottom: 0,
            transform: "translate(-50%, -50%)",
            // opacity: "0.5",
            zIndex: 999,
          }}
        >
          <div
            className="d-flex align-items-center gap-3 p-3  text-white letter-space"
            style={{
              background: "rgba( 131, 43, 103, 0.55 )",
              boxShadow: " 0 8px 32px 0 rgba( 31, 38, 135, 0.3 )",
              backdropFilter: "blur( 20px )",
              // "-webkit-backdrop-filter": "blur( 20px )",
              borderRadius: "10px",
              border: "1px solid rgba( 255, 255, 255, 0.3 )",
              height: 100,
            }}
          >
            <CircularProgress
              style={{
                color: "#832b67",
              }}
            />{" "}
            Loading
          </div>
        </div>
      ) : (
        <div className="container ">
          <RevoCalendar
            events={events}
            style={{
              height: "100%",
              borderRadius: "5px",
              // border: "5px solid #4F6995",
              // padding:" 0 10px",
            }}
            highlightToday={true}
            lang="en"
            primaryColor="#2a2f83"
            secondaryColor="#f8f9fa"
            todayColor="#832B67"
            textColor="#000"
            indicatorColor="orange"
            animationSpeed={300}
            sidebarWidth={180}
            detailWidth={280}
            showDetailToggler={true}
            showSidebarToggler={true}
            onePanelAtATime={false}
            allowDeleteEvent={false}
            allowAddEvent={false}
            openDetailsOnDateSelection={true}
            timeFormat24={true}
            showAllDayLabel={true}
            sidebarDefault={false}
            detailDefault={false}
            // dateSelected={(e) => {
            //   dateChange(e.day, e.month, e.year);
            // }}
            // detailDateFormat={date}

          />
        </div>
      )}
    </div>
  );
}

export default EventCalender;
