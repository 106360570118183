import { CircularProgress } from '@mui/material';
import { Button, Result } from 'antd';
import confetti from 'canvas-confetti';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import FetchApi from '../config/ApiCal';
function SuccessPage() {
  const navigators = useNavigate();
  const [loading, setloading] = useState(true)
  const [show, setshow] = useState(false)
  const [error, seterror] = useState(false)
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  useEffect(() => {
    console.log(searchParams, 'ef')
    // Accessing individual query parameters
    const success = searchParams.get('success');
    const _token = searchParams.get('_token');
    const session_id = searchParams.get('session_id');
    console.log({ success, _token, session_id }, 'obhhh')
    FetchApi('success', { success, _token, session_id }).then(res => {
      console.log(res, 'apiccckkal');

      if (res.message == 'success') {
        if (res.result) {
          handleClick()
        }
        seterror(!res.result)

      }
      setloading(false)
    }).catch(e => toast.error(e))

  }, [])

  const handleClick = () => {
    var defaults = {
      origin: { y: 0.7 }
    };

    function fire(particleRatio, opts) {
      confetti({
        ...defaults,
        ...opts,
        particleCount: Math.floor(200 * particleRatio)
      });
    }

    fire(0.25, {
      spread: 26,
      startVelocity: 55,
    });
    fire(0.2, {
      spread: 60,
    });
    fire(0.35, {
      spread: 100,
      decay: 0.91,
      scalar: 0.8
    });
    fire(0.1, {
      spread: 120,
      startVelocity: 25,
      decay: 0.92,
      scalar: 1.2
    });
    fire(0.1, {
      spread: 120,
      startVelocity: 45,
    });
  };
  return (
    <div className='d-flex justify-content-center align-items-center bg-white mt-5 my-5 container' style={{ minHeight: '70vh', position: 'relative' }}>
      <ToastContainer
        position="top-right"
        autoClose={3000}
        limit={1}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
      {
        loading ?
          (<div
            style={{
              position: "absolute",
              top: "50%",
              left: "50%",
              bottom: 0,
              transform: "translate(-50%, -50%)",
              // opacity: "0.5",
              zIndex: 999,
            }}
          >
            <div
              className="d-flex align-items-center gap-3 p-3  text-white letter-space"
              style={{
                background: "rgba( 131, 43, 103, 0.55 )",
                boxShadow: " 0 8px 32px 0 rgba( 31, 38, 135, 0.3 )",
                backdropFilter: "blur( 20px )",
                // "-webkit-backdrop-filter": "blur( 20px )",
                borderRadius: "10px",
                border: "1px solid rgba( 255, 255, 255, 0.3 )",
                height: 100,
              }}
            >
              <CircularProgress
                style={{
                  color: "#832b67",
                }}
              />{" "}
              Loading
            </div>
          </div>

          ) : (

            <Result
              className=''
              status={error ? 'error' : "success"}
              title={error ? 'Try Again' : "Payment Successfully"}
              subTitle={error ? 'Something Went Wrong' : "Subscription Plan Activated "}
              extra={[
                <Button type="primary" key="console" onClick={() => {
                  navigators('/')
                  setTimeout(() => {
                    window.location.reload();
                  }, 1200);
                }}>
                  Go Home
                </Button>,

              ]}
            />
          )
      }

    </div>
  )
}

export default SuccessPage