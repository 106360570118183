import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import FetchApi from '../config/ApiCal';
import { Colors } from '../config/Colors';
import ListComponent from './ListComponent';

function Wallet() {
    const [app, setapp] = useState({
        code: 'gbp'
    });
    const [wallet, setwallet] = useState(0)
    const LoginData = useSelector((state) => state.LgnData);

    const navigate = useNavigate();
    useEffect(() => {
        window.scrollTo({ top: 0, behavior: "smooth" });
        ListApi()
        // return () => {
        FetchApi("appsetting").then(async (data) => {
            const currency = data?.app_setting?.default_currency;
            // console.log(data);
            await setapp({
                currency: data.currency.find((e) => e.id == currency)?.symbol,
                all: data.app_setting,
                code: data.currency.find((e) => e.id == currency)?.short_name
            });
        });
        // };
    }, []);
    const ListApi = async () => {
        const datalist = await FetchApi('profileView', { id: LoginData.id, offset: 0 });
        // console.log(datalist);
        setwallet(datalist?.data?.wallet_balance)
    }
    const scrollToTop = () => {
        window.scrollTo({ top: 0, behavior: "smooth" });
    };
    return (
        <div className="container mt-5  " >
            <div className="">
                <button
                    className="text-white  rounded-2 some_btn   bg-app-color"
                    onClick={() =>
                        navigate("/profile")
                    }
                >
                    {/* <IoMdArrowDropleftCircle />  */}
                    Back
                </button>
            </div>
            <div className=' my-5 mt-5 bg-white rounded-2' style={{ minHeight: '80vh' }} >
                <div className="text-center pt-4 ">
                    <div className="top  fs-3 fw-bold  py-2 pb-0">
                        <span className='app-color' >{app?.currency}</span> {wallet} <span className='text-uppercase base-color'>{app?.code}</span>
                    </div>
                    <span className='letter-space base-color' style={{ letterSpacing: 2 }}>Wallet Balance</span>
                </div>

                <hr />
                <div className="" style={{ position: 'relative' }}>
                    <h5 style={{ color: Colors.baseColor }} className='ps-3'>
                        Transcation
                        <span className='mx-1' style={{ color: Colors.appColor }}> History</span>
                    </h5>

                    <ListComponent url={'CustomerTransaction'} content={"wallet"} scroll={scrollToTop} />


                </div>
            </div>
        </div>

    )
}

export default Wallet;
export const WalletList = ({ items, symbol }) => {
    return (
        <div className="container">
            {
                items.map((dat, i) => {


                    return (
                        <div className="row border m-0 p-3 rounded-2 my-1 mx-1 " key={i}>
                            <div className='col-10  col-lg-10 col-md-10    text-response'>
                                {dat.transaction_notes}
                                <br />
                                {dat.created_at}
                            </div>

                            <div className="col-2  col-lg-2 col-md-2 p-0">
                                <p className='m-0 mb-1'>  {symbol}{dat.transaction_amount}</p>
                                <div className={`badge fw-normal rounded text-uppercase  text-response ${dat.transaction_type == 'debit' ? 'bg-app-color' : 'bg-base-color'}`}>
                                    {dat.transaction_type}

                                </div>
                            </div>
                        </div>
                    )
                })
            }

        </div>
    )
}