import { CircularProgress, Typography } from "@mui/material";
import { Empty } from "antd";
import React, { useEffect, useRef, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import FetchApi from "../config/ApiCal";
import { OnRequest } from "./OnRequest";
import { OnGoing } from "./Ongoing";
import { WalletList } from "./Wallet";

const ListComponent = ({
  url,
  type = '',
  content,
  track = true,
  bottom = true,
  scroll,
  cancel = false
}) => {
  const [items, setItems] = useState([]);
  const [hasMore, setHasMore] = useState(true);
  const [page, setPage] = useState(0);
  const navigate = useNavigate();
  const LoginData = useSelector((state) => state.LgnData);
  // console.log(LoginData, "profile");
  const [loading, setLoading] = useState(false);
  const hasEffectRun = useRef(false);
  const [app, setapp] = useState(null);
  useEffect(() => {
    // Fetch initial data when the component mounts
    if (!hasEffectRun.current) {
      // Your logic that should run only once
      fetchData();
      // Update the ref to indicate that the effect has run
      hasEffectRun.current = true;
    }
    // setPage(0);
  }, []);
  useEffect(() => {
    FetchApi("appsetting").then((data) => {
      const currency = data?.app_setting?.default_currency;
      // console.log(currency);
      setapp(data.currency.find((e) => e.id == currency)?.symbol);
    });
  }, []);

  useEffect(() => {
    scroll();
  }, [loading]);

  const fetchData = async () => {
    // console.log("called");
    setLoading(true);
    // console.log(page, "page");

    const response = await FetchApi(url, {
      offset: page,
      type,
      customer_id: LoginData?.id,
    })
      .then((e) => {
        setPage((prevPage) => prevPage + 10);

        const newData = e.result;
        // console.log(newData, "apicall");
        setHasMore(newData.length > 0);
        if (newData.length > 0) {
          setItems((prevItems) => [...prevItems, ...newData]);
        }
        setLoading(false);
      })
      .catch((e) => {
        setLoading(false);
        console.error("Error fetching data:", e);
      });

    // Increment the page for the next request
    // catch (error)
  };
  //   console.log(loading, "state");
  useEffect(() => {
    if (type === "upcoming") {
      global.upcoming_count = items.length;
      // console.log(items,'dfdfdfdfdfd')
    }
    // console.log(content);
    // console.log(items.map((e) => e.booking_id).filter((e) => e != undefined));
  }, [items]);

  return (
    <div className="list-scroll">
      <InfiniteScroll
        dataLength={items.length}
        next={fetchData}
        hasMore={hasMore}
        loader={null}
        endMessage={
          items.length > 0 ? (
            <Typography
              variant="subtitle1"
              align="center"
              className="base-color fw-bold fs-5 mt-3"
            >
              No more data to load
            </Typography>
          ) : null
        }
        style={{ overflow: "hidden" }}
      >
        {content == "request" ? (
          <OnRequest items={items} symbol={app} track={track} bottom={bottom} />
        ) : content == 'wallet' ? (<WalletList items={items} symbol={app} />) : (
          <OnGoing
            items={items}
            track={track}
            bottom={bottom}
            type={type}
            symbol={app}
            isCancel={cancel}
            navigate={navigate}
          />
        )}
      </InfiniteScroll>

      {loading ? (
        <div
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            bottom: 0,
            transform: "translate(-50%, -50%)",
            // opacity: "0.5",
            zIndex: 999,
          }}
        >
          <div
            className="d-flex align-items-center gap-3 p-3  text-white letter-space"
            style={{
              background: "rgba( 131, 43, 103, 0.55 )",
              boxShadow: " 0 8px 32px 0 rgba( 31, 38, 135, 0.3 )",
              backdropFilter: "blur( 20px )",
              // "-webkit-backdrop-filter": "blur( 20px )",
              borderRadius: "10px",
              border: "1px solid rgba( 255, 255, 255, 0.3 )",
              height: 100,
            }}
          >
            <CircularProgress
              style={{
                color: "#832b67",
              }}
            />{" "}
            Loading
          </div>
        </div>
      ) : null}

      {!loading && items.length === 0 && (
        <div className="bg-white">
          <Empty
            image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
            imageStyle={{ height: "250px" }}
            description={<span>No Data Found</span>}
          />
        </div>
      )}
    </div>
  );
};

export default ListComponent;
