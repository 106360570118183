import { CircularProgress } from '@mui/material';
import { IoIosArrowDropleftCircle } from "@react-icons/all-files/io/IoIosArrowDropleftCircle";
import { IoIosArrowDroprightCircle } from "@react-icons/all-files/io/IoIosArrowDroprightCircle";
import { Button, Empty, Modal, Switch } from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from "react-toastify";
import FetchApi from '../config/ApiCal';
import { Colors } from '../config/Colors';
import "../styles/profile.css";
function PlanList() {
    const containerRef = useRef(null);
    const [scrollPosition, setScrollPosition] = useState(0);
    const [isDragging, setIsDragging] = useState(false);
    const [startX, setStartX] = useState(0);
    const [trigger, settrigger] = useState(false);
    const [list, setlist] = useState([]);
    const [loading, setloading] = useState(true)
    const [selectPlan, setselectPlan] = useState({})
    const [renew, setrenew] = useState(true)
    const [buttonLoader, setbuttonLoader] = useState(false)
    const scrollLeft = () => {
        const scrollAmount = 18 * 16; // 18rem * 16px (assuming 1rem = 16px)
        containerRef.current.scrollLeft -= scrollAmount;
        setScrollPosition(containerRef.current.scrollLeft);
    };
    const navigate = useNavigate();
    const LoginData = useSelector((state) => state.LgnData);

    const scrollRight = () => {
        const scrollAmount = 18 * 16; // 18rem * 16px (assuming 1rem = 16px)
        containerRef.current.scrollLeft += scrollAmount;
        setScrollPosition(containerRef.current.scrollLeft);
    };
    const handleMouseDown = (event) => {
        setIsDragging(true);
        setStartX(event.clientX - containerRef.current.offsetLeft);
    };

    const handleMouseMove = (event) => {
        if (!isDragging) return;

        const x = event.clientX - containerRef.current.offsetLeft;
        const dragDistance = x - startX;
        containerRef.current.scrollLeft = scrollPosition - dragDistance;
    };

    const handleMouseUp = () => {
        setIsDragging(false);
        setScrollPosition(containerRef.current.scrollLeft);
    };
    const handlePop = (e) => {
        settrigger(true)
        setselectPlan(e)
    }
    const onChange = (checked) => {
        // console.log(`switch to ${checked}`);
        setrenew(checked)
    };
    useEffect(() => {

        const apiRes = FetchApi('subscriptionList', {
            user_type: 1
        }).then(res => {
            console.log(res, 'data');
            setlist(res.result ?? []);
            setloading(false)
        })

    }, [])

    useEffect(() => {
        setrenew(true)
    }, [selectPlan])

    const OnPay = async () => {
        setbuttonLoader(true)
        console.log('calll');
        const dataList = await FetchApi('createCheckoutSession', {
            user_id: LoginData?.id,
            type: 1,
            plan_id: selectPlan.id,
            auto_renew: renew ? 1 : 0
        })
        console.log(dataList, 'ffff');
        if (dataList.result != null) {
            window.location.replace(dataList.result.url);

        } else {

            toast.error(dataList.message)
            setTimeout(() => {
                navigate('/')
            }, 1500);
        }

        setbuttonLoader(false)
    }

    return (
        <div className='container mt-5'  >
            <ToastContainer
                position="top-right"
                autoClose={3000}
                limit={1}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="colored"
            />{" "}
            <div className="p-1 " style={{ position: 'relative', minHeight: loading ? '70vh' : '0' }}>
                <h2 style={{ color: Colors.baseColor }}>
                    Subscription Plans
                    <span style={{ color: Colors.appColor }}> For Customer </span>
                </h2>

                {
                    loading ?
                        (<div
                            style={{
                                position: "absolute",
                                top: "50%",
                                left: "50%",
                                bottom: 0,
                                transform: "translate(-50%, -50%)",
                                // opacity: "0.5",
                                zIndex: 999,
                            }}
                        >
                            <div
                                className="d-flex align-items-center gap-3 p-3  text-white letter-space"
                                style={{
                                    background: "rgba( 131, 43, 103, 0.55 )",
                                    boxShadow: " 0 8px 32px 0 rgba( 31, 38, 135, 0.3 )",
                                    backdropFilter: "blur( 20px )",
                                    // "-webkit-backdrop-filter": "blur( 20px )",
                                    borderRadius: "10px",
                                    border: "1px solid rgba( 255, 255, 255, 0.3 )",
                                    height: 100,
                                }}
                            >
                                <CircularProgress
                                    style={{
                                        color: "#832b67",
                                    }}
                                />{" "}
                                Loading
                            </div>
                        </div>

                        ) : (
                            <div className="" style={{ display: !loading && list.length == 0 ? 'none' : 'block' }}>



                                <IoIosArrowDropleftCircle style={{ fontSize: '58px' }} className=' pointer app-color arrow-left' onClick={scrollLeft} />

                                <div className="sub-list rounded-2  hidden-scroll d-flex  align-items-center mt-5" style={{ minHeight: '70vh', maxWidth: '100%', overflowX: 'scroll', scrollSnapType: 'x mandatory', position: 'relative' }}
                                    ref={containerRef}
                                    onMouseDown={handleMouseDown}
                                    onMouseMove={handleMouseMove}
                                    onMouseUp={handleMouseUp}
                                    onMouseLeave={handleMouseUp}
                                    onTouchStart={(event) => {
                                        handleMouseDown(event.touches[0]);
                                    }}
                                    onTouchMove={(event) => {
                                        handleMouseMove(event.touches[0]);
                                    }}
                                    onTouchEnd={handleMouseUp}
                                >
                                    {
                                        list.map((e, i) => {
                                            return (
                                                <>

                                                    <div className="rounded-2 card-style p-3  " style={{ cursor: isDragging ? 'grabbing' : 'default' }}>
                                                        <div className="top p-2 pt-2    border-bottom ">
                                                            <div className='d-flex justify-content-between align-items-center'>
                                                                <h5 className='py-2'>{e.name}</h5>
                                                                {
                                                                    e.popular == 1 && (

                                                                        <p className='m-0  popular' >Popular</p>
                                                                    )

                                                                }
                                                            </div>
                                                            <div className=' pt-2 pb-0 mb-0 d-flex align-items-center gap-2'><p className='fs-2 m-0 p-0 sm-text'>{e.currency_symbol} {e.amount}</p> <p className='text-muted sm-text m-0 p-0 ms-1 fs-5 fw-none'>/ {e.period} {e.period_type == 1 ? 'month' : 'year'}</p> </div>
                                                            <span style={{ fontSize: 12 }} className=' text-muted py-2 sm-text'>{e.sub_description}</span>

                                                        </div>
                                                        <div className="dynamic-list mt-2 card-list hidden-scroll" dangerouslySetInnerHTML={{ __html: e.description }}>


                                                        </div>
                                                        {/* <ul className='card-list mt-2  '>
                                                            <li>Free Job</li>
                                                            <li>Cash Back</li>
                                                            <li>Discount </li>

                                                        </ul> */}
                                                        <button className='border-0 bottom-btn' onClick={() => handlePop(e)}>Get Started</button>
                                                    </div>

                                                </>
                                            )
                                        })
                                    }

                                </div>
                                <IoIosArrowDroprightCircle style={{ fontSize: '58px' }} onClick={scrollRight} className=' pointer app-color arrow-right' />


                            </div>

                        )

                }
                {
                    !loading && list.length == 0 ? (
                        <div className="d-flex justify-content-center align-items-center bg-white rounded-2 mt-5" style={{ minHeight: '70vh' }}>
                            <Empty
                                image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
                                imageStyle={{
                                    height: 250,
                                }}
                                description={null}


                            >

                                <p className='fw-bold base-color fs-2'>Subscription  Not Available</p>
                            </Empty>
                        </div>
                    ) : null
                }
            </div>
            <Modal
                centered
                title={""}
                open={trigger}
                onOk={null}
                onCancel={null}
                className=""
                footer={null}
                closable={false}
            >
                <div className="d-flex justify-content-center flex-column  ">
                    <div className="top p-2 pt-2    border-bottom ">
                        <div className='d-flex justify-content-between align-items-center'>
                            <h5 className='py-2'>{selectPlan?.name}</h5>
                            {
                                selectPlan?.popular == 1 && (

                                    <p className='m-0  popular' >Popular</p>
                                )

                            }
                        </div>
                        <div className=' pt-2 pb-0 mb-0 d-flex align-items-center gap-2'><p className='fs-2 m-0 p-0 sm-text'>{selectPlan?.currency_symbol} {selectPlan?.amount}</p> <p className='text-muted sm-text m-0 p-0 ms-1 fs-5 fw-none'>/ {selectPlan?.period} {selectPlan?.period_type == 1 ? 'month' : 'year'}</p> </div>
                        <span style={{ fontSize: 12 }} className=' text-muted py-2 sm-text'>{selectPlan?.sub_description}</span>
                        <br />
                        <div className="text-center my-4">
                            <Switch defaultChecked onChange={onChange} value={renew} id='auto' />
                            <label className='ms-2' htmlFor="auto" >Auto Renew ( recommended )</label>
                        </div>

                    </div>
                    <div className="d-flex justify-content-center align-items-center my-2 gap-3">

                        <Button className='btn btn-danger' onClick={() => settrigger(false)}>Close</Button>
                        <Button className='btn btn-success px-2 ' onClick={OnPay} loading={buttonLoader}>Pay Now</Button>
                        {/* <button className=''></button> */}

                    </div>
                </div>
            </Modal>
        </div>
    )
}

export default PlanList