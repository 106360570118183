import { RiEyeOffLine } from "@react-icons/all-files/ri/RiEyeOffLine";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import store from "../Redux/Store";
import FetchApi from "../config/ApiCal";
import { LoginString } from "../config/LoginString";
import "../styles/Login.css"; //login css applied here
import "../styles/Register.css"; //new class are maintained in this file

const Register = () => {
  const socialLoginData = useSelector((state) => state.socialLoginData);
  const [type, setType] = useState("password");
  const navigate = useNavigate();
  const [regbtnLoad, setRegIgBtnLoad] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const [country, setCountry] = useState([]);
  const [regState, setRegState] = useState({
    firstname:
      socialLoginData && socialLoginData.socialLoginData
        ? socialLoginData.socialLoginData.first_name
        : "",
    lastname:
      socialLoginData && socialLoginData.socialLoginData
        ? socialLoginData.socialLoginData.last_name
        : "",
    email:
      socialLoginData && socialLoginData.socialLoginData
        ? socialLoginData.socialLoginData.email
        : "",
    phonenumber: "",
    password: "",
    selectedValue: "+44",
  });
  const [regErr, setRegErr] = useState({
    firstNameErr: "",
    lastNameErr: "",
    RegemailErr: "",
    phoneNumberErr: "",
    passwordErr: "",
    selectErr: "",
  });
  const handleCheckboxChange = () => {
    setIsChecked(true);
  };

  //initially called countries api after mounted
  useEffect(() => {
    fetchData();
  }, []);

  async function fetchData() {
    const ApiResponse = await FetchApi("getCountriesCode");
    setCountry(ApiResponse.data);
  }
  //
  const IGRegister = async (e) => {
    e.preventDefault();
    setRegIgBtnLoad(true);

    if (RegValidation()) {
      if (!isChecked) {
        toast.error("Please Accept Terms & Condition", {
          position: "top-right",
          autoClose: 1000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        setRegIgBtnLoad(false);
      } else {
        const rgparams = {
          first_name: regState.firstname,
          name: regState.firstname,
          last_name: regState.lastname,
          email: regState.email,
          phone_number: regState.phonenumber,
          password: regState.password,
          country_code: regState.selectedValue,
          type: "web",
          device_id: 123456,
          customer_id:
            socialLoginData && socialLoginData.socialLoginData
              ? socialLoginData.socialLoginData.customer_id
              : "",
        };

        //api call
        const url =
          socialLoginData && socialLoginData.socialLoginData
            ? "customer/socialUpdate"
            : "auth/register";
        const ApiRegResponse = await FetchApi(url, rgparams);
        console.log(ApiRegResponse, "gfwsufg");
        if (
          ApiRegResponse.message == "Registered Successfully" ||
          ApiRegResponse.message == "User Details"
        ) {
          // console.log("if");
          toast.success("Registered Successfully", {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });

          setTimeout(() => {
            if (ApiRegResponse.message == "Registered Successfully") {
              navigate("/login");
            } else {
              const loginData = {
                id: ApiRegResponse.result.id,
                first_name: ApiRegResponse.result.first_name,
                last_name: ApiRegResponse.result.last_name,
                avatar: ApiRegResponse.result.avatar,
                email: ApiRegResponse.result.email,
                country_code: ApiRegResponse.result.country_code,
                phone_number: ApiRegResponse.result.phone_number,
                wallet_balance: ApiRegResponse.result.wallet_balance,
              };
              store.dispatch({ type: "addUserData", payload: loginData });
              localStorage.setItem("user_id", ApiRegResponse.result.id);
              store.dispatch({ type: "clearSocialData" });
              setTimeout(() => {
                navigate("/");
                window.scrollTo({ top: 0, behavior: "smooth" });
              }, 1000);
            }
            setRegState({
              firstname: "",
              lastname: "",
              email: "",
              phonenumber: "",
              password: "",
              selectedValue: "",
            });
            setRegIgBtnLoad(false);
            window.scrollTo({ top: 0, behavior: "smooth" });
          }, 2000);
        } else {
          toast.error(ApiRegResponse.message, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
          setRegIgBtnLoad(false);
        }
      }
    } else {
      setRegIgBtnLoad(false);
    }
  };

  const RegValidation = () => {
    let isValid = true;
    let firstNameErr = "";
    let lastNameErr = "";
    let phoneNumberErr = "";
    let passwordErr = "";
    let RegemailErr;
    let selectErr = "";

    if (regState.firstname == "") {
      firstNameErr = "Enter First Name";
      isValid = false;
    }
    if (regState.lastname == "") {
      lastNameErr = "Enter Last Name";
      isValid = false;
    }
    if (regState.phonenumber == "") {
      phoneNumberErr = "Enter Phone Number";
      isValid = false;
    }
    if (socialLoginData && socialLoginData.socialLoginData == undefined) {
      if (regState.password == "") {
        passwordErr = "Enter your password";
        isValid = false;
      } else if (regState.password.length <= 8) {
        passwordErr = "password must min 8 character";
        isValid = false;
      }
    }
    if (regState.email != "") {
      let lastAtPos = regState.email.lastIndexOf("@");
      let lastDotPos = regState.email.lastIndexOf(".");
      if (
        !(
          lastAtPos < lastDotPos &&
          lastAtPos > 0 &&
          regState.email.indexOf("@@") == -1 &&
          lastDotPos > 2
        )
      ) {
        isValid = false;
        RegemailErr = "Enter Valid Email";
      }
    } else {
      RegemailErr = "Enter Your Email";
    }
    if (regState.selectedValue == "") {
      selectErr = "Choose CC";
      isValid = false;
    }

    if (!isValid) {
      setRegErr({
        firstNameErr: firstNameErr,
        lastNameErr: lastNameErr,
        RegemailErr: RegemailErr,
        phoneNumberErr: phoneNumberErr,
        passwordErr: passwordErr,
        selectErr: selectErr,
      });
    } else {
      setRegErr({
        firstNameErr: firstNameErr,
        lastNameErr: lastNameErr,
        RegemailErr: RegemailErr,
        phoneNumberErr: phoneNumberErr,
        passwordErr: passwordErr,
        selectErr: selectErr,
      });
    }
    return isValid;
  };

  const handleShowHide = (eyechange) => {
    eyechange === "password" ? setType("password") : setType("text");
  };

  const handleMobileInputChange = (e) => {
    // Allow only digits (0-9)
    const sanitizedValue = e.target.value.replace(/[^0-9]/g, "");
    // Update state with the sanitized value
    setRegState({
      ...regState,
      phonenumber: sanitizedValue,
    });
  };

  return (
    <div
      className="Login-Section"
      style={{ minHeight: "100vh", paddingBottom: "120px" }}
    >
      <div className="d-flex  justify-content-center">
        <div className="Login-title-txt text-center">
          <span className="Login-frtext">{LoginString.RegisterTitle}</span>{" "}
          <span className="Login-sectext">{LoginString.RegisterTitle2}</span>
          <div>
            <span className="subtitle-text">
              {LoginString.RegisterSubTitle}
            </span>
          </div>
        </div>
      </div>
      <div className="container mt-4">
        <div className="row d-flex text-align-center justify-content-center">
          <div className="col-lg-5">
            <div className="LoginBox">
              <div className="Input-Box-Section pt-3 pb-2">
                <div className="lable-section">
                  <form autoComplete="off">
                    <div
                      className={
                        regErr.firstNameErr
                          ? "form-group grp-2"
                          : "form-group grp-1"
                      }
                    >
                      <label htmlFor="InputFirstName1" className="form-labels">
                        {LoginString.RegisterFNLabel}
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="InputFirstName1"
                        aria-describedby="NameHelp"
                        placeholder="Enter first name"
                        onChange={(e) =>
                          setRegState({
                            ...regState,
                            firstname: e.target.value,
                          })
                        }
                        onFocus={() =>
                          setRegErr({ ...regErr, firstNameErr: "" })
                        }
                        value={regState.firstname}
                      />
                      <span className="Errors">
                        {regErr.firstNameErr ? regErr.firstNameErr : ""}
                      </span>
                    </div>
                    <div
                      className={
                        regErr.lastNameErr
                          ? "form-group grp-2"
                          : "form-group grp-1"
                      }
                    >
                      <label htmlFor="InputLasttName1" className="form-labels">
                        {LoginString.RegisterLNLabel}
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="InputLasttName1"
                        aria-describedby="LNameHelp"
                        placeholder="Enter last name"
                        onChange={(e) =>
                          setRegState({ ...regState, lastname: e.target.value })
                        }
                        onFocus={() =>
                          setRegErr({ ...regErr, lastNameErr: "" })
                        }
                        value={regState.lastname}
                      />
                      <span className="Errors">
                        {regErr.lastNameErr ? regErr.lastNameErr : ""}
                      </span>
                    </div>
                    <div
                      className={
                        regErr.RegemailErr
                          ? "form-group grp-2"
                          : "form-group grp-1"
                      }
                    >
                      <label
                        htmlFor="exampleInputEmail1"
                        className="form-labels"
                      >
                        {LoginString.Label1}
                      </label>
                      <input
                        type="email"
                        className="form-control"
                        id="exampleInputEmail1"
                        aria-describedby="emailHelp"
                        placeholder="Enter email"
                        onChange={(e) =>
                          setRegState({ ...regState, email: e.target.value })
                        }
                        onFocus={() =>
                          setRegErr({ ...regErr, RegemailErr: "" })
                        }
                        disabled={
                          socialLoginData && socialLoginData.socialLoginData
                        }
                        value={regState.email}
                      />
                      <span className="Errors">
                        {regErr.RegemailErr ? regErr.RegemailErr : ""}
                      </span>
                    </div>

                    <div className="row">
                      <label htmlFor="examplemobile" className="form-labels">
                        {LoginString.RegisterMNLabel}
                      </label>
                      <div className="col-4 col-lg-3">
                        <div
                          className={
                            regErr.selectErr
                              ? "form-group grp-2"
                              : "form-group grp-1"
                          }
                          id="countryInput"
                        >
                          {/* <input type="number" className="form-control" id="examplecountrycode" aria-describedby="emailHelp" placeholder="CC"/> */}
                          <select
                            className="form-control"
                            id="examplecountrycode"
                            aria-describedby="emailHelp"
                            value={regState.selectedValue}
                            onChange={(e) =>
                              setRegState({
                                ...regState,
                                selectedValue: e.target.value,
                              })
                            }
                            onFocus={() =>
                              setRegErr({ ...regErr, selectErr: "" })
                            }
                          >
                            {/* <option value="">CC</option> */}
                            {country.filter(e => e.short_name == 'GB').map((cntry) => {
                              return (
                                <option
                                  key={cntry.id}
                                  selected
                                  value={"+" + cntry.phone_code}
                                >
                                  +{cntry.phone_code}-{cntry.short_name}
                                </option>
                              );
                            })}
                          </select>
                          <span className={regErr.selectErr ? "Errors" : ""}>
                            {regErr.selectErr ? regErr.selectErr : ""}
                          </span>
                        </div>
                      </div>
                      <div className="col-8 col-lg-9">
                        <div
                          className={
                            regErr.phoneNumberErr
                              ? "form-group grp-2"
                              : "form-group grp-1"
                          }
                          id="countryInput"
                        >
                          <input
                            type="text"
                            className="form-control"
                            id="examplemobile"
                            aria-describedby="emailHelp"
                            placeholder="Enter Mobile Number"
                            onChange={(e) => handleMobileInputChange(e)}
                            onFocus={() =>
                              setRegErr({ ...regErr, phoneNumberErr: "" })
                            }
                            value={regState.phonenumber}
                          />
                          <span className="Errors">
                            {regErr.phoneNumberErr ? regErr.phoneNumberErr : ""}
                          </span>
                        </div>
                      </div>
                    </div>

                    {socialLoginData && socialLoginData.socialLoginData ? (
                      ""
                    ) : (
                      <div
                        className={
                          regErr.passwordErr
                            ? "form-group grp-2"
                            : "form-group grp-1"
                        }
                        id="passwordInput"
                      >
                        <label
                          htmlFor="exampleInputPassword1"
                          className="form-labels"
                        >
                          {LoginString.Label2}
                        </label>
                        <span className="eyevector">
                          {type === "password" ? (
                            <RiEyeOffLine
                              style={{ fontSize: "25px" }}
                              onClick={() => handleShowHide("text")}
                            />

                          ) : (
                            <svg
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                              onClick={() => handleShowHide("password")}
                            >
                              <path
                                d="M11.9999 16.3299C9.60992 16.3299 7.66992 14.3899 7.66992 11.9999C7.66992 9.60992 9.60992 7.66992 11.9999 7.66992C14.3899 7.66992 16.3299 9.60992 16.3299 11.9999C16.3299 14.3899 14.3899 16.3299 11.9999 16.3299ZM11.9999 9.16992C10.4399 9.16992 9.16992 10.4399 9.16992 11.9999C9.16992 13.5599 10.4399 14.8299 11.9999 14.8299C13.5599 14.8299 14.8299 13.5599 14.8299 11.9999C14.8299 10.4399 13.5599 9.16992 11.9999 9.16992Z"
                                fill="#042E60"
                              />
                              <path
                                d="M2.67146 14.7308L2.67075 14.7297C2.2037 14.0027 1.95508 13.0171 1.95508 12.0037C1.95508 10.9907 2.20356 10.0021 2.67121 9.26951C5.05218 5.55738 8.45904 3.47998 12.0001 3.47998C15.5413 3.47998 18.948 5.55756 21.3187 9.26913L21.3194 9.27023C21.7865 9.99724 22.0351 10.9828 22.0351 11.9962C22.0351 13.0095 21.7865 13.9982 21.3187 14.7309C18.9479 18.4424 15.5413 20.52 12.0001 20.52C8.44863 20.52 5.04205 18.4422 2.67146 14.7308ZM12.0001 3.97998C8.55682 3.97998 5.32506 6.04702 3.09877 9.54072C2.65861 10.2277 2.45758 11.1313 2.45758 12C2.45758 12.8684 2.6585 13.7718 3.0984 14.4587C5.3247 17.9527 8.55663 20.02 12.0001 20.02C15.4433 20.02 18.6751 17.9529 20.9014 14.4592C21.3415 13.7723 21.5426 12.8686 21.5426 12C21.5426 11.1312 21.3415 10.2274 20.9012 9.54039C18.6749 6.04689 15.4432 3.97998 12.0001 3.97998Z"
                                fill="#042E60"
                                stroke="#042E60"
                              />
                            </svg>
                          )}
                        </span>
                        <input
                          type={type}
                          className="form-control"
                          id="exampleInputPassword1"
                          placeholder="Password"
                          onChange={(e) =>
                            setRegState({
                              ...regState,
                              password: e.target.value,
                            })
                          }
                          onFocus={() =>
                            setRegErr({ ...regErr, passwordErr: "" })
                          }
                          value={regState.password}
                        />
                        <span className="Errors">
                          {regErr.passwordErr ? regErr.passwordErr : ""}
                        </span>
                      </div>
                    )}

                    {/* <p className='pswrd-desc'>{LoginString.Regpswdesc}</p> */}
                    <div className="terms-condition-accept d-flex gap-2 pt-5">
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id="flexCheckDefault"
                          checked={isChecked}
                          onChange={handleCheckboxChange}
                        />
                      </div>
                      <p className="by-accepting">
                        By registering you accept our{" "}
                        <span
                          style={{ color: "#0158FF", cursor: "pointer" }}
                          onClick={() => navigate("/termsConditions")}
                        >
                          {LoginString.Term}
                        </span>{" "}
                        and{" "}
                        <span
                          style={{ color: "#0158FF", cursor: "pointer" }}
                          onClick={() => navigate("/privacypolicy")}
                        >
                          {LoginString.Poli}
                        </span>
                      </p>
                    </div>
                    <div className="Register-Buttons pt-3 text-center">
                      {/* <div className='d-flex flex-column-reverse flex-lg-row flex-md-row justify-content-between align-items-center'> */}
                      {/* <span className='fpwrd' style={{cursor:'pointer'}}>{LoginString.LoginFP}</span> */}
                      <button
                        type="button"
                        className="rgster-btn"
                        onClick={IGRegister}
                        disabled={regbtnLoad}
                      >
                        {regbtnLoad ? (
                          <small className="spinner-border spinner-border-sm"></small>
                        ) : (
                          LoginString.RegisterLetsGo
                        )}
                      </button>
                      {/* </div> */}
                    </div>
                  </form>
                </div>

                <div className="pt-4 pb-5 text-center">
                  <span className="dont-have-accnt">
                    {LoginString.RegAlready}
                  </span>{" "}
                  <span className="rgternow" onClick={() => navigate("/login")}>
                    {LoginString.RegLogin}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer
        position="top-right"
        autoClose={3000}
        limit={1}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
    </div>
  );
};

export default Register;
