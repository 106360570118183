import { Backdrop, CircularProgress } from "@mui/material";
import { Autocomplete, useLoadScript } from "@react-google-maps/api";
import { IoMdArrowDropleftCircle } from '@react-icons/all-files/io/IoMdArrowDropleftCircle';
import { IoMdArrowForward } from '@react-icons/all-files/io/IoMdArrowForward';
import dayjs from "dayjs";
import React, { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import validator from "validator";
import store from "../Redux/Store";
import FetchApi from "../config/ApiCal";
import { imageUrl } from "../config/Const";
import "../styles/booking.css";
import DatePicker from "./DatePicker";
import { TextInput } from "./TextInput";
function Booking() {
  const loaction = useLocation();
  const navigators = useNavigate();
  const LoginData = useSelector((state) => state.LgnData);
  const tripType = useSelector((state) => state.jobType);
  // console.log(tripType, "rtrt");

  // console.log(LoginData);
  const navigate = useNavigate();
  const [mode, setmode] = useState(tripType == 2);
  const [bookLoader, setbookLoader] = useState(false);
  const [serviceLoader, setserviceLoader] = useState(true);
  const [laterErr, setlaterErr] = useState({
    start: false,
    end: false,
  });
  const [error, seterror] = useState({});
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: "AIzaSyBxwzbehjrT3hVubyS9VWBPBTvglagBDv0",
    libraries: ["places"],
  });
  const [places, setplace] = useState(null);
  const [serviceList, setServiceList] = useState([]);
  const [fromDate, setFromDate] = useState();
  const [endDate, setendDate] = useState();
  const [initial, Setinitial] = useState(false);
  const [minHours, setminHours] = useState(1)
  // console.log(endDate);
  // console.log(dayjs(fromDate).format('DD-MM-YYYY HH:mm'),'daarre')
  const [bookingData, setbookingData] = useState({
    mobile: LoginData?.country_code + "  " + LoginData?.phone_number,
    email: LoginData?.email,
    customer_location: "",
    postcode: "",
    country: "",
    city: "",
    service_id: 0,
    service_name: "",
    service_image: "",
    payment_type: "",
    strip_token: "",
    promocode: "",
    drop_lat: "0",
    drop_lon: "0",
    customer_id: LoginData?.id,
    mode: "",
    special_instruction: "",
    total_agent: tripType == 1 ? '2' : '1',
  });

  // useEffect(() => {
  //   setendDate(null);
  // }, [fromDate]);

  // useEffect(() => {
  //   if ("geolocation" in navigator) {
  //     navigator.geolocation.getCurrentPosition(
  //       (position) => {
  //         const { latitude, longitude } = position.coords;
  //         // getFullAddress(latitude, longitude);
  //       },
  //       (error) => {
  //         console.error("Error getting location:", error.message);
  //       }
  //     );
  //   } else {
  //     console.error("Geolocation is not supported.");
  //   }
  // }, [initial]);

  const getFullAddress = async (lat, lng) => {
    const apiKey = "AIzaSyBxwzbehjrT3hVubyS9VWBPBTvglagBDv0";
    const apiUrl = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=${apiKey}`;

    try {
      const response = await fetch(apiUrl);
      const data = await response.json();
      if (data.status === "OK" && data.results.length > 0) {
        const addressComponents = data.results[0].address_components;
        const formattedAddress = data.results[0].formatted_address;

        // Extract specific address components
        const city = addressComponents.find((component) =>
          component.types.includes("locality")
        );
        // const state = addressComponents.find(component => component.types.includes('administrative_area_level_1'));
        const country = addressComponents.find((component) =>
          component.types.includes("country")
        );
        const postcode = addressComponents.find((component) =>
          component.types.includes("postal_code")
        );
        setbookingData({
          ...bookingData,
          customer_lat: lat,
          customer_lon: lng,
          customer_location: formattedAddress,
          drop_location: "NoDrop",
          postcode: postcode ? postcode.long_name : null,
          country: country ? country.long_name : null,
          city: city ? city.long_name : null,
        });
      } else {
        console.error("Error retrieving address:", data.status);
        return null;
      }
    } catch (error) {
      console.error("Error retrieving address:", error);
      return null;
    }
  };

  const onLoad = (a) => {
    setplace(a);
  };
  // console.log(bookingData);
  const onPlaceChanged = (place) => {
    if (places != null) {
      //variable to store the result
      const place = places.getPlace();
      const { lat, lng } = place.geometry.location;
      // console.log(place);
      let postcode = "";
      let country = "";
      let city = "";

      // Iterate through the address components
      place.address_components.forEach((component) => {
        if (component.types.includes("postal_code")) {
          postcode = component.short_name;
        } else if (component.types.includes("country")) {
          country = component.long_name;
        } else if (component.types.includes("locality")) {
          city = component.long_name;
        }
      });
      setbookingData({
        ...bookingData,
        customer_lat: lat(),
        customer_lon: lng(),
        customer_location: place?.formatted_address,
        drop_location: "NoDrop",
        postcode,
        country,
        city,
      });
    } else {
      toast.error("Enter your loaction");
    }
  };
  useEffect(() => {
    let user = localStorage.getItem("user_id");
    if (user == undefined || user == null) {
      navigators("/");
      return;
    }
    FetchApi("Service", { id: loaction.state })
      .then((e) => {
        if (e.data.length > 0) {
          // console.log(e.data[0]);
          const defaultValue = {
            ...bookingData,
            service_id: e.data[0].id,
            service_image: e.data[0].service_image,
            service_name: e.data[0].service_name,
            category: e.data[0].category_id,
          };
          // console.log(defaultValue, "value");
          // serviceList[0].select = true;
          setminHours(e.data[0]?.book_guard_min_time)
          console.log(e, 'data');
          setbookingData(defaultValue);
          // setendDate(dayjs().add(e.data[0]?.book_guard_min_time + 1, 'hours'))
          // setServiceList([...serviceList]);
        }
        setserviceLoader(false);
        setServiceList(
          e.data.map((e, i) => {
            return { ...e, select: i == 0 ? true : false };
          })
        );
        Setinitial(!initial);
      })
      .catch((e) => toast.error(e));
  }, []);

  const handleText = useCallback((e) => {

    setbookingData((prevBookingData) => ({
      ...prevBookingData,
      [e.target.name]: e.target.value,
    }));
    // console.log(e.target.value, e.target.name);
  }, []);
  const validatorFiels = () => {
    let allvalue = true;
    if (validator.isEmpty(bookingData?.customer_location) && !bookingData.customer_lat) {
      seterror({ ...error, customer_location: "Required" });
      allvalue = false;
    }

    if (!validator.isNumeric(bookingData.total_agent) || bookingData.total_agent > 20 || bookingData.total_agent < 1) {
      seterror({ ...error, total_agent: "Only accept Numberic and should not contain more than 20 Guard" });
      allvalue = false;
    }
    if (tripType == 1 && bookingData.total_agent < 2) {
      seterror({ ...error, total_agent: "Please enter a minimum of 2 guards for an SOS emergency" });
      allvalue = false;
    }

    if (bookingData.service_id == 0) {
      toast.error("Select Service");
      allvalue = false;
    }
    if (mode) {
      if (fromDate == null) {
        allvalue = false;
        setlaterErr({ ...laterErr, start: true });
      }
      if (endDate == null) {
        allvalue = false;
        setlaterErr({ ...laterErr, end: true });
      }

      const startDate = dayjs(fromDate);
      const timeDiffInHours = dayjs(endDate).diff(startDate, 'hour');
      // console.log(timeDiffInHours, endDate, 'time');
      if (timeDiffInHours < minHours) {
        allvalue = false;
        setlaterErr({ ...laterErr, end: true });
        toast.error(`Min ${minHours} hours for Book a Guard service`)
      }
    }

    if (allvalue) seterror({});
    return allvalue;
  };
  const postData = (mod) => {
    if (validatorFiels()) {
      const times = {
        start_date_time: dayjs(fromDate).format("YYYY-MM-DD HH:mm"),
        end_date_time: dayjs(endDate).format("YYYY-MM-DD HH:mm"),

      };
      console.log(times, 'main')

      // return;
      setbookLoader(true);
      FetchApi("FareEstimate", {
        customer_id: LoginData?.id,
        service_id: bookingData?.service_id,
        promocode: bookingData?.promocode,
        type: mod,
        ...times,
        total_agent: bookingData.total_agent
      })
        .then((e) => {
          setbookLoader(false);
          const sendTo = {
            ...e.result,
            service_name: bookingData.service_name,
            service_image: bookingData.service_image,
            loader: true,
            total_amount: e.result.overall_total,
            vat_amount: e.result.before_vat_amount,
            mode: "ridenow",
            trip_type: mod == "ridenow" ? 1 : 2,
            ...times,
          };
          console.log(sendTo, "fare");
          // console.log(sendTo.mode, "modeeeee");
          store.dispatch({
            type: "addtostore",
            payload: { ...bookingData, ...sendTo },
          });
          navigators("payment", {
            state: sendTo,
          });
          // console.log(e, "res");
        })
        .catch((e) => toast.error(e))
        .finally((e) => {
          setbookLoader(false);
        });
    }
  };
  const addtoSelect = (i, list) => {
    // console.log(list);
    removeSelect();
    serviceList[i].select = true;
    setServiceList([...serviceList]);
    setbookingData({
      ...bookingData,
      service_id: list.id,
      service_image: list.service_image,
      service_name: list.service_name,
      category: list.category_id,
    });
    setminHours(serviceList[i]?.book_guard_min_time)
  };
  const removeSelect = () => {
    setServiceList(serviceList.map((item) => (item.select = false)));
  };
  const manageLater = () => {
    if (!mode) {
      setmode(true);
    } else {
      postData("ridelater");
    }
  };
  const swapUi = () => {
    setmode(!mode);
    store.dispatch({ type: "setType", payload: mode ? 1 : 2 });
  };
  const ErrorRemover = (e) => {
    console.log(e.target.name);
    seterror({ ...error, [e.target.name]: '' })
  }
  return (
    <div className="">
      {/* <TwiloReceiver /> */}
      <ToastContainer
        position="top-right"
        autoClose={3000}
        limit={1}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />{" "}
      <Backdrop
        style={{
          color: "#fff",
          zIndex: 99,
          // height: "100vh",
        }}
        open={bookLoader}
        className=""
      >
        {/* <CircularProgress /> */}
        <div className="position-absolute " style={{ zIndex: 3 }}>
          <div
            className="d-flex align-items-center gap-3 p-3 px-5 text-white letter-space"
            style={{
              background: "rgba( 131, 43, 103, 0.55 )",
              boxShadow: " 0 8px 32px 0 rgba( 31, 38, 135, 0.3 )",
              backdropFilter: "blur( 20px )",
              // "-webkit-backdrop-filter": "blur( 20px )",
              borderRadius: "10px",
              border: "1px solid rgba( 255, 255, 255, 0.3 )",
              height: 100,
            }}
          >
            <CircularProgress
              style={{
                color: "#2a2f83",
              }}
            />{" "}
            Loading
          </div>
        </div>
      </Backdrop>
      <div className="container  mt-3" style={{ minHeight: "50vh" }}>
        <div className="header  text-center pt-3">
          <h3 className="base-color mb-0  letter-space">
            Post a <span className="app-color ">Request</span>
          </h3>
          <span className="text-muted mt-0" style={{ fontSize: 14 }}>
            Please fill your details
          </span>
        </div>
        <div className="">
          <button
            className="text-white  rounded-2 some_btn   bg-app-color"
            onClick={() => navigate("/services")}
          >
            <IoMdArrowDropleftCircle />
            Back
          </button>
        </div>
        <div className="main bg-white rounded-2 p-2 mt-4">
          <div className="select mt-3 mb-2">
            <div className="d-flex justify-content-between align-items-center">
              <div className="mb-0 base-color fw-bold ">
                Select <span className="app-color">Service</span>
              </div>
              <div className="">
                <button
                  onClick={swapUi}
                  className={`p-2 border-0 text-center text-white rounded-2 ${mode ? "bg-app-color" : "bg-base-color"
                    }`}
                  style={{ fontSize: 12 }}
                >
                  Switch to {mode ? "SOS" : "Book Guard"}
                </button>
              </div>
            </div>

            <hr />
            {serviceLoader && (
              <div className="text-center pt-3">
                <CircularProgress
                  style={{
                    color: "#2a2f83",
                  }}
                />{" "}
              </div>
            )}

            <div
              className={`list-service d-flex gap-3 ${serviceList.length > 4
                ? "justify-content-center"
                : "justify-content-start"
                } `}
            >
              {serviceList.map((e, i) => {
                return (
                  <div className="d-flex flex-column  justify-content-center align-items-center ">
                    <div
                      key={i}
                      onClick={() => addtoSelect(i, e)}
                      className={`service-div ${e.select ? "service-div-active" : false
                        }`}
                    >
                      <img
                        src={imageUrl + "uploads/" + e.service_image}
                        alt=""
                        className="service-image position-relative  "
                      />
                    </div>
                    <span style={{ fontSize: 12 }} className="mt-1">
                      {" "}
                      {e.service_name}
                    </span>
                  </div>
                );
              })}
            </div>
          </div>
          <div className="details pt-2">
            <div className="mb-0 base-color fw-bold mb-3">
              Other <span className="app-color">Details</span>
              <hr />
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-md-6 col-lg-6">
              <TextInput
                lable={"Mobile Number"}
                place={"Enter Your Mobile Number"}
                id={"mobile"}
                name={"mobile"}
                value={bookingData?.mobile}
                change={handleText}
                read
                error={error?.mobile}
              />
            </div>
            <div className="col-12 col-md-6 col-lg-6">
              <TextInput
                lable={"Email Address"}
                place={"Enter Your Email ID"}
                id={"email"}
                value={bookingData?.email}
                name={"email"}
                read
                change={handleText}
                error={error?.email}
              />
            </div>
            <div className="col-12 col-md-6 col-lg-6">
              <TextInput
                lable={"No.of.Guard"}
                place={"No of Guard"}
                id={"nom"}
                value={bookingData?.total_agent}
                name={"total_agent"}
                read={false}
                change={handleText}
                type={'number'}
                error={error?.total_agent}
                onFocus={ErrorRemover}
              />
            </div>
            <div className="col-12 mt-2 col-md-6 col-lg-6">
              <div className="">
                {isLoaded && (
                  <Autocomplete
                    onPlaceChanged={(place) => onPlaceChanged(place)}
                    onLoad={onLoad}
                    restrictions={{
                      country: ["GB", "IN"],
                    }}
                  >
                    {/* <input
                  type="text"
                  id={"place"}
                  className="inp-book w-100"
                  placeholder={"Enter a Location"}
                /> */}
                    <TextInput
                      lable={" Address"}
                      place={"Enter Your Location"}
                      id={"location"}
                      name={"customer_location"}
                      value={bookingData?.customer_location}
                      change={handleText}
                      error={error?.customer_location}
                      onFocus={ErrorRemover}

                    />
                  </Autocomplete>
                )}
              </div>
            </div>
            <div className="col-12 col-md-6 col-lg-6">
              <TextInput
                lable={"Post Code"}
                place={""}
                id={"pos"}
                name={'postcode'}
                read={false}
                value={bookingData?.postcode}
                change={handleText}
              />
              <span className="text-muted ms-2">
                *This field is autofill from address
              </span>
            </div>
            <div className="col-12 col-md-6 col-lg-6">
              <TextInput
                lable={"City"}
                place={""}
                id={"city"}
                name={'city'}
                read={false}
                change={handleText}
                value={bookingData?.city}
              />
              <span className="text-muted ms-2">
                *This field is autofill from address
              </span>
            </div>
            <div className="col-12 ">
              <TextInput
                lable={" Description"}
                place={" Please tell us your emergency"}
                id={"special_instruction"}
                read={false}
                change={handleText}
                name={"special_instruction"}
                value={bookingData?.special_instruction}

              />
            </div>
            {/* <div className="col-12 ">
              <TextInput
                lable={" Promocode"}
                place={"Enter your Promocode"}
                id={"spen"}
                read={false}
                change={handleText}
                name={"promocode"}
                value={bookingData?.promocode}
              />
            </div> */}
          </div>
          {mode && (
            <>
              <div className="details pt-2">
                <div className="mb-0 base-color fw-bold mb-3">
                  Book Your <span className="app-color">Dates</span>
                  {/* <hr /> */}
                </div>
              </div>
              <div className="row p-1">
                <div
                  className={`col-12 col-md-6 col-lg-6  ${laterErr.start ? "date-pick-book-error" : "date-pick-book"
                    }`}
                >
                  {/* <DatePicker label="Basic date picker" /> */}
                  <label
                    htmlFor=""
                    className={`${laterErr.start ? "text-danger" : "text-muted"
                      }  mb-0`}
                  >
                    Start Date & Time
                  </label>
                  <DatePicker
                    focus={() => {
                      setlaterErr({
                        start: false,
                        end: false,
                      });

                      // setFromDate("");
                    }}
                    lable={"Start Date "}
                    mdate={dayjs().add(30, "minutes")}
                    change={setFromDate}
                    value={fromDate}
                  />
                </div>
                <div
                  className={`col-12 col-md-6 col-lg-6 ${laterErr.end ? "date-pick-book-error" : "date-pick-book"
                    }`}
                >
                  <label
                    htmlFor=""
                    className={`${laterErr.end ? "text-danger" : "text-muted"}`}
                  >
                    End Date & Time{" "}
                  </label>
                  <DatePicker
                    lable={"End Date "}
                    mdate={dayjs(fromDate).add(minHours, "hours")}
                    change={setendDate}
                    disabled={fromDate == null}
                    value={endDate}
                    focus={() =>
                      setlaterErr({
                        start: false,
                        end: false,
                      })
                    }
                  />
                </div>
              </div>
            </>
          )}

          <div className="d-flex justify-content-end gap-4 my-3 ">
            {/* {mode && (
              <p
                onClick={() => setmode(false)}
                className="px-2 p-2  rounded-2  pointer text-white bg-app-color"
              >
                Close
              </p>
            )} */}
            {mode && (
              <p
                className="px-2 p-2  rounded-2 d-flex align-items-center gap-2  pointer text-white bg-base-color"
                onClick={manageLater}
              >
                Proceed
                <IoMdArrowForward />
              </p>
            )}

            {!mode && (
              <p
                className="px-4 d-flex align-items-center gap-2  p-2 rounded-2  pointer text-white bg-app-color"
                onClick={() => postData("ridenow")}
              >
                Proceed
                {/* <IoMdArrowForward /> */}
              </p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Booking;
