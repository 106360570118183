import { CircularProgress } from "@mui/material";
import { FaStar } from "@react-icons/all-files/fa/FaStar";
import { IoMdArrowDropleftCircle } from "@react-icons/all-files/io/IoMdArrowDropleftCircle";
import { Modal, Rate } from "antd";
import TextArea from "antd/es/input/TextArea";
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import DefaultImage from "../asserts/defaultpic.jpg";
import FetchApi from "../config/ApiCal";
import { imageUrl } from "../config/Const";
function ViewBooking() {
  const [error, seterror] = useState("");
  const [loading, setloading] = useState(false);
  const booking_data = useSelector((state) => state.historyTrip);
  const view = booking_data;
  const [feedback, setfeedback] = useState(
    view.group_driver_data ?? []
  );
  const LoginData = useSelector((state) => state.LgnData);

  const [reviewModel, setreviewModel] = useState(false);

  // console.log(view);
  const [postData, setPost] = useState({
    from_id: view?.customer_id,
    to_id: view?.driver_id,
    job_id: view?.booking_id,
    rating: 0,
    feedback: "",
    status: 0,
  });
  const [loader, setloader] = useState(false);
  const navigate = useNavigate();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const showModal = () => {
    setIsModalOpen(true);
  };
  useEffect(() => {
    if (postData.rating == 0) {
      seterror("Rating Field is Required");
    } else {
      seterror("");
    }
  }, [postData.rating]);
  useEffect(() => {
    setPost({ ...postData, rating: 0, feedback: "" });
  }, [isModalOpen]);

  const handleOk = () => {
    // setIsModalOpen(false);
    if (postData.rating > 0) {
      setloading(true);
      // console.log(postData, "pist ");
      FetchApi("CustomerFeedbackStore", postData)
        .then((result) => {
          console.log(result);
          seterror("");
          setloading(false);
          setPost({});

          if (result.message == "Feedback received") {
            toast.success("Feedback added");
            // setIsModalOpen(false);
            setTimeout(() => {
              navigate("/bookingDetails", { state: 3 });
            }, 1000);
          }
        })
        .catch((err) => { });
    }
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const onChangeFeedback = (data, i, type) => {
    feedback[i][type] = data;
    setfeedback([...feedback]);
  };
  const feedBackSumbit = () => {
    console.log(feedback, 'red')
    const checkValidator = feedback.filter((e) => e.rating && e.rating > 0);
    console.log(checkValidator, 'css')
    if (checkValidator.length > 0) {
      setloader(true);
      FetchApi("MultiCustomerFeedbackStore", {
        job_id: booking_data?.booking_id,
        from_id: LoginData?.id,
        ratings_data: JSON.stringify(feedback),
      }).then(res => {
        toast.success(res.message)
        feedBackCancel()
        setTimeout(() => {
          navigate("/bookingDetails", { state: 3 });
        }, 1000);
      });
      // console.log(data);
    } else {
      toast.error('Need To Add Alteast One Review ')

    }
    // console.log(feedback,'eeeded')
    // setloader(true);
    // const { data } = FetchApi("MultiCustomerFeedbackStore", {
    //   job_id: booking_data?.booking_id,e
    //   from_id: LoginData?.id,
    //   ratings_data: JSON.stringify(feedback),
    // });
    // console.log(data);

    // feedBackCancel()e
  };
  const feedBackCancel = () => {
    setreviewModel(false)
  };
  // console.log(view);
  return (
    <div className="container mt-5 mb-2">
      <ToastContainer />
      <div className="text-decoration-none d-flex  justify-content-start align-items-center  ">
        {/* <NavLink to="/bookingDetails" className={"text-decorateion-none"}>
          Booking Request >
          <span className="fw-bold base-color"> Booking Details</span>
        </NavLink> */}
        <div
          className="text-white rounded-2 pointer  bg-app-color p-2"
          onClick={() =>
            navigate("/bookingDetails", {
              state: 3,
            })
          }
        >
          <IoMdArrowDropleftCircle />
          Back
        </div>
      </div>
      <div className="row bg-white rounded-2 mt-5">
        <div className="col-12 col-md-4 col-lg-4 mt-2" style={{ maxHeight: '550px' }}>
          <p className="text-center base-color fw-bold">Guard's <span className="app-color">List</span>  </p>
          {
            feedback.map((data) => {
              return (
                <div className="d-flex align-items-center border rounded-1  p-2 gap-3">
                  <div className="">
                    <img
                      src={
                        data.avatar != null ? imageUrl + data.avatar
                          : DefaultImage
                      }
                      alt=""
                      className="img-fluid view-image"
                    />
                  </div>
                  <div className="">
                    <table cellPadding={3} className="w-100" style={{ fontSize: 14 }}>
                      <tbody >
                        <tr>
                          <td className="text-muted">Guard Name</td>
                          <td>:</td>
                          <td className='base-color'> {data?.first_name}</td>
                        </tr>
                        <tr>
                          <td className="text-muted">Email</td>
                          <td>:</td>
                          <td className='base-color'>{data?.email}</td>
                        </tr>
                        <tr>
                          <td className="text-muted">Phone</td>
                          <td>:</td>
                          <td className='base-color'>{data?.phone_number}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              )
            })
          }

        </div>
        <div className="col-12 col-md-8 col-lg-8">
          <div className="bg-white p-2 rounded-2 mx-1 mt-3">
            <div className="top d-flex justify-content-between  align-items-center border-bottom ">
              <div className="left">
                <div className="top-card d-flex p-1 align-items-center">
                  <div className="d-flex   w-100  justify-content-between   align-items-center  ">
                    <div className="left ms-3">
                      <table
                        className="mt-1 p-1"
                        // style={{ fontSize: "" }}
                        cellPadding={3}
                      >
                        <tr>
                          <td className="text-muted">Booking ID </td>
                          <td>:</td>
                          <th className="base-color"># {view?.booking_id}</th>
                        </tr>
                        <tr>
                          <td className="text-muted">Job no </td>
                          <td>:</td>
                          <th className="base-color ">{view?.job_number}</th>
                        </tr>
                        {view?.rating > 0 && (
                          <tr>
                            <td
                              colSpan={3}
                              className=""
                              align="right"
                              style={{ color: "#E5CB2E" }}
                            >
                              <span>
                                <FaStar />
                              </span>
                              <span className="text-muted ms-2  ">
                                {view?.rating}
                              </span>
                              <span className="base-color fw-bold ">
                                {" "}
                                ({view?.review_count} reviews)
                              </span>
                            </td>
                          </tr>
                        )}
                      </table>
                    </div>
                  </div>
                </div>
              </div>
              <div className="right">
                <p className="font-sm font-md font-lg base-color fw-bold mb-1">
                  $ {view?.total_amount}
                </p>

                <p
                  className="m-0 mb-1 mt-0  p-1 px-2 text-white rounded-4 font-sm"
                  style={{
                    background: "green",
                    width: "fit-content",
                    fontSize: 12,
                  }}
                >
                  Completed
                </p>
              </div>
            </div>
            <div className="serveice   my-2 border-bottom p-2">
              <div className="base-color fw-bold">
                <p className="my-2">Service</p>
                {view?.category_name}{" "}
              </div>
              <div className="text-muted my-2">{view?.service_name}</div>
            </div>
            <div className="serveice   my-2 border-bottom p-2">
              <div className="base-color fw-bold">
                <p className="my-2">Booking Date</p>
              </div>
              <p className="text-muted font-sm" style={{ fontSize: 14 }}>
                {view?.job_date}
              </p>
              {/* <div className="text-muted my-2">{view?.service_name}</div> */}
            </div>
            {view.drop_location !== "NoDrop" && (
              <div className="serveice   my-2 border-bottom p-2">
                <div className="base-color fw-bold">
                  <p className="my-2">Pick Up Location</p>
                  {/* {view?.category_name}{" "} */}
                </div>
                <div className="text-muted my-2">{view?.drop_location}</div>
              </div>
            )}
            <div className="p-2">
              <p className="my-2 fw-bold  base-color">Billing Info</p>

              <table className="w-100">
                <tr>
                  <td className="text-muted">Dates</td>
                  <td align="right" className="base-color">
                    {dayjs(view?.job_date).format('DD-MM-YYYY HH:mm')}

                  </td>
                </tr>
                <tr>
                  <td className="text-muted">Total no.of hours</td>
                  <td align="right" className="base-color">
                    {view?.total_hrs} hrs
                  </td>
                </tr>{" "}
                <tr>
                  <td className="text-muted">Booking ID</td>
                  <td align="right" className="base-color">
                    # {view?.booking_id}
                  </td>
                </tr>{" "}
                {view.promo_discount > 0 && (
                  <tr>
                    <td className="text-muted">Promo Code</td>
                    <td align="right" className="base-color">
                      $ {view?.promo_discount}
                    </td>
                  </tr>
                )}
                <tr>
                  <td className="text-muted">Total Bill</td>
                  <td align="right" className="base-color fw-bold fs-5">
                    $ {view?.total_amount}
                  </td>
                </tr>
              </table>
            </div>
            {view?.rating_status === "No" && feedback.length > 0 ? (
              <div className="text-end my-3">
                <button
                  onClick={() => {
                    setreviewModel(true);
                  }}
                  className="border-0 p-2 rounded-2  bg-app-color text-white"
                >
                  Add Job Feedback
                </button>
              </div>
            ) : (
              <div className="">
                {/* <p className="my-2 base-color">Review</p>
                <Rate disabled value={view?.rating}></Rate>
                <p className="text-muted mt-2">{view?.feedback}</p> */}
              </div>
            )}
            {/* //single */}
            {/* <Modal
          //   title="Add Review"

          open={isModalOpen}
          onOk={handleOk}
          onCancel={handleCancel}
          centered
          confirmLoading={loading}
        >
          <div className="text-center">
            <img
              src={
                view?.driver_avatar.toString().trim().length > 0
                  ? imageUrl + view?.driver_avatar
                  : DefaultImage
              }
              alt=""
              className="img-fluid view-image"
            />
            <p className="base-color mt-2 mb-1">
              How was your experience with{" "}
            </p>
            <p className="base-color fw-bold text-center">
              {view?.driver_name}
            </p>
            <Rate
              className="d-block"
              onChange={(e) => setPost({ ...postData, rating: e })}
            />
            {error != "" && <p className="text-danger m-2">{error}</p>}
            <textarea
              name=""
              className="mt-3 border rounded-1 remove-oultine"
              placeholder="write your review here."
              id=""
              cols="30"
              onChange={(e) =>
                setPost({ ...postData, feedback: e.target.value })
              }
              rows="5"
              style={{ backgroundColor: "#f2f3f4" }}
            ></textarea>
          </div>
        </Modal> */}

            <Modal
              centered
              title={""}
              open={reviewModel}
              onOk={null}
              onCancel={null}
              className=""
              footer={null}
              closable={false}
            >
              <div className="d-flex justify-content-center flex-column  ">
                {loader ? (
                  <div className="d-flex justify-content-center ">
                    <CircularProgress />
                  </div>
                ) : (
                  <>
                    <div className="review_model">
                      <div className=" base-color fs-5 text-center  fw-bold ">
                        Please give feedback on the  <span className="app-color">Insta-Guard officer</span>
                      </div>
                      <div className="">
                        {feedback.map((e, i) => {
                          // console.log(e)
                          return (
                            <div className="d-flex row align-items-center   border my-3 p-2">
                              <div className="col-3 d-flex flex-column gap-1 align-items-center p-0">
                                <img
                                  src={
                                    e.avatar == null
                                      ? require("../asserts/defaultpic.jpg")
                                      : imageUrl + e.avatar
                                  }
                                  // src={require("../asserts/defaultpic.jpg")}
                                  alt=""
                                  className="img-fluid driver-image rounded-circle"
                                  srcset=""
                                />
                                <span className="driver-name">
                                  {e.first_name}
                                </span>
                              </div>

                              <div className="input col-9">
                                <Rate
                                  id="rating"
                                  className="my-3"
                                  allowHalf
                                  value={e.rating}
                                  key={i}
                                  onChange={(e) =>
                                    onChangeFeedback(e, i, "rating")
                                  }
                                />{" "}
                                <br />
                                <TextArea
                                  placeholder="Write Review"
                                  key={i + "feed"}
                                  id="feedback"
                                  autoSize={{
                                    minRows: 3,
                                    maxRows: 6,
                                  }}
                                  value={e.feedback}
                                  className="w-100"
                                  onChange={(e) =>
                                    onChangeFeedback(
                                      e.target.value,
                                      i,
                                      "feedback"
                                    )
                                  }
                                />
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    </div>

                    <div className="d-flex justify-content-center  align-items-center gap-2 mt-2 mb-3">
                      <button
                        className="border-0 p-1 text-white bg-app-color rounded-2 px-2"
                        onClick={feedBackCancel}
                      >
                        Cancel
                      </button>
                      <button
                        onClick={feedBackSumbit}
                        className="border-0 p-1 text-white bg-base-color rounded-2 px-3"
                      >
                        OK
                      </button>
                    </div>
                  </>
                )}
              </div>
            </Modal>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ViewBooking;
