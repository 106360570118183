import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import FetchApi from "../config/ApiCal";
import { LoginString } from "../config/LoginString";

const ForgotPassword = () => {
  const [email, setEmail] = useState("");
  const [error, seterror] = useState("");
  const [btnLoadStatus, setBtnLoadStatus] = useState(false);
  const navigate = useNavigate();

  const handleSubmit = async () => {
    if (email.length > 0) {
      setBtnLoadStatus(true);
      const params = {
        email: email,
        mode: "customer",
      };
      const ApiResponse = await FetchApi("forgetpass", params); //updatePassword
      // console.log("API Res -->", ApiResponse);
      if (ApiResponse?.message == "Mail sent successfully") {
        // console.log("API Res--> ", ApiResponse.result);
        localStorage.setItem("text_conut", ApiResponse.result.otp);
        localStorage.setItem("forget_email", ApiResponse.result.email);

        toast.success("Mail sent successfully", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        setTimeout(() => {
          setEmail("");
          setBtnLoadStatus(false);
          navigate("/verifyAccount");
          window.scrollTo({ top: 0, behavior: "smooth" });
        }, 2000);
      } else {
        toast.error(ApiResponse.message, {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        setBtnLoadStatus(false);
      }
    } else {
      seterror("Email field is required");
    }
  };
  return (
    <div className="Login-Section" style={{ minHeight: "100vh" }}>
      <ToastContainer />
      <div className="d-flex  justify-content-center">
        <div className="Login-title-txt text-center">
          <span className="Login-frtext">{LoginString.ForgotTitle1}</span>{" "}
          <span className="Login-sectext">{LoginString.ForgotTitle12}</span>
          <div>
            <span className="subtitle-text">{LoginString.ForgotSubtitle}</span>
          </div>
        </div>
      </div>
      <div className="container mt-4">
        <div className="row d-flex text-align-center justify-content-center">
          <div className="col-lg-5">
            <div className="LoginBox">
              <div className="Input-Box-Section pt-3 pb-2">
                <div className="lable-section">
                  <form>
                    <div className="form-group grp-1">
                      <label for="exampleInputEmail1" className="form-labels">
                        {LoginString.Label1}
                      </label>
                      <input
                        type="email"
                        className="form-control"
                        id="exampleInputEmail1"
                        aria-describedby="emailHelp"
                        placeholder="Enter email"
                        onChange={(e) => setEmail(e.target.value)}
                        onFocus={(e) => seterror("")}
                        value={email}
                      />
                      {error != "" && (
                        <div className="text-danger">{error}</div>
                      )}
                    </div>
                  </form>
                </div>

                <div className="Login-Buttons pt-5 pb-5 text-center">
                  <button
                    type="button"
                    className="rgster-btn"
                    onClick={handleSubmit}
                    disabled={btnLoadStatus}
                  >
                    {btnLoadStatus ? (
                      <small className="spinner-border spinner-border-sm"></small>
                    ) : (
                      LoginString.ForgotSubmit
                    )}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
