import { RiEyeOffLine } from "@react-icons/all-files/ri/RiEyeOffLine";
import React, { useState } from "react";
import OtpInput from "react-otp-input";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import FetchApi from "../config/ApiCal";
import { LoginString } from "../config/LoginString";
import "../styles/Register.css";

const VerifyAccount = () => {
  const [otp, setOtp] = useState("");
  const [verify, SetVerify] = useState(false);
  const [type, setType] = useState("password");
  const [type1, setType1] = useState("password");
  const [btnLoadStatus, setBtnLoadStatus] = useState(false);
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [errorMessage01, setErrorMessage01] = useState("");
  const navigate = useNavigate();

  const handleShowHide = (eyechange) => {
    eyechange === "password" ? setType("password") : setType("text");
  };

  const handleShowHide1 = (eyechange) => {
    eyechange === "password" ? setType1("password") : setType1("text");
  };
  const NumOtp = localStorage.getItem("text_conut");

  const handleVerify = () => {
    setBtnLoadStatus(true);
    console.log("entered otp -->", otp);
    console.log("OTP --> ", NumOtp);
    if (NumOtp === otp) {
      toast.success("OTP Verified Successfully", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      setTimeout(() => {
        setOtp("");
        setBtnLoadStatus(false);
        SetVerify(true);
      }, 3000);
    } else {
      toast.error("Enter correct OTP", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      setTimeout(() => {
        setOtp("");
        setBtnLoadStatus(false);
      }, 3000);
    }
  };

  const validate = (value) => {
    // if (
    //   validator.isStrongPassword(value, {
    //     minLength: 8,
    //     minLowercase: 1,
    //     minUppercase: 1,
    //     minNumbers: 1,
    //     minSymbols: 1,
    //   })
    // ) {
    //   setErrorMessage("Is Strong Password");
    setNewPassword(value);
    // } else {
    //   setErrorMessage("Is Not Strong Password");
    // }
  };

  const validateConfirm = (value) => {
    if (value == newPassword) {
      setErrorMessage01("Confirm Password Same");
      setConfirmPassword(value);
    } else {
      setErrorMessage01("Confirm Password Must Be Same");
    }
    if (value.length < 8) {
      setErrorMessage01("password must min 8 character");

    }

  };

  const handleSubmit = async () => {
    setBtnLoadStatus(true);
    if (
      // errorMessage === "Is Strong Password" &&
      errorMessage01 === "Confirm Password Same"
    ) {
      const params = {
        mode: "customer",
        type: "web",
        email: localStorage.getItem("forget_email"),
        password: confirmPassword,
      };
      const ApiResponse = await FetchApi("updatePassword", params);
      if (ApiResponse?.message == "Customer password updated successfuly.") {
        toast.success("Customer password updated successfuly", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        setTimeout(() => {
          setBtnLoadStatus(false);
          SetVerify(false);
          setNewPassword("");
          setConfirmPassword("");
          navigate("/login");
        }, 3000);
      } else {
        toast.error(ApiResponse?.message, {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        setTimeout(() => {
          SetVerify(true);
          setBtnLoadStatus(false);
        }, 3000);
      }
    } else {
      setBtnLoadStatus(false);
    }
  };

  return (
    <div className="Login-Section" style={{ minHeight: "100vh" }}>
      <div className="d-flex  justify-content-center">
        <div className="Login-title-txt text-center">
          <span className="Login-frtext">
            {verify === false
              ? LoginString.VerifyTitle
              : LoginString.ResetPTitle}
          </span>{" "}
          <span className="Login-sectext">
            {verify === false
              ? LoginString.VerifyTitle2
              : LoginString.ResetPTitle2}
          </span>
          <div>
            <span className="subtitle-text">
              {verify === false ? LoginString.VerifySub : LoginString.ResetPDes}
            </span>
          </div>
        </div>
      </div>
      {verify === false ? (
        <>
          <div className="container mt-4">
            <div className="row d-flex text-align-center justify-content-center">
              <div className="col-lg-5">
                <div className="LoginBox">
                  <div className="Input-Box-Section pt-3 pb-2  text-center">
                    <div className="VerityInputs">
                      <OtpInput
                        value={otp}
                        onChange={setOtp}
                        numInputs={4}
                        renderSeparator={<span className="m-1"></span>}
                        isInputNum
                        inputType="number"
                        renderInput={(props) => <input {...props} />}
                        inputStyle={{
                          border: `1px solid #F8F8F8`,
                          borderRadius: "10px",
                          backgroundColor: "#DFDFDF",
                          width: "60px",
                          height: "60px",
                          fontSize: "22px",
                          color: "#042E60",
                          fontWeight: "400",
                        }}
                      />
                    </div>
                    <p
                      style={{
                        paddingTop: "3rem",
                        color: "#7F7E89",
                        fontFamily: "Inter",
                        fontSize: "16px",
                      }}
                    >
                      {LoginString.VerifyDidntReceive}{" "}
                      <span style={{ color: "#0158FF", cursor: "pointer" }}>
                        {LoginString.VerifyRA}
                      </span>
                    </p>
                    <div
                      className="Login-Buttons pt-3"
                      style={{ marginBottom: "8rem" }}
                    >
                      <button
                        type="button"
                        className="lgn-btn"
                        onClick={handleVerify}
                        disabled={btnLoadStatus}
                      >
                        {btnLoadStatus ? (
                          <small className="spinner-border spinner-border-sm"></small>
                        ) : (
                          LoginString.VerifyBtn
                        )}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        <>
          <div className="container mt-4">
            <div className="row d-flex text-align-center justify-content-center">
              <div className="col-lg-5">
                <div className="LoginBox">
                  <div className="Input-Box-Section pt-3 pb-2">
                    <div className="lable-section">
                      <form>
                        <div className="form-group grp-1" id="passwordInput">
                          <label
                            for="exampleInputPassword1"
                            className="form-labels"
                          >
                            {LoginString.ResetNewP}
                          </label>
                          <span className="eyevector">
                            {type === "password" ? (
                              <RiEyeOffLine
                                style={{ fontSize: "25px" }}
                                onClick={() => handleShowHide("text")}
                              />

                            ) : (
                              <svg
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                                onClick={() => handleShowHide("password")}
                              >
                                <path
                                  d="M11.9999 16.3299C9.60992 16.3299 7.66992 14.3899 7.66992 11.9999C7.66992 9.60992 9.60992 7.66992 11.9999 7.66992C14.3899 7.66992 16.3299 9.60992 16.3299 11.9999C16.3299 14.3899 14.3899 16.3299 11.9999 16.3299ZM11.9999 9.16992C10.4399 9.16992 9.16992 10.4399 9.16992 11.9999C9.16992 13.5599 10.4399 14.8299 11.9999 14.8299C13.5599 14.8299 14.8299 13.5599 14.8299 11.9999C14.8299 10.4399 13.5599 9.16992 11.9999 9.16992Z"
                                  fill="#042E60"
                                />
                                <path
                                  d="M2.67146 14.7308L2.67075 14.7297C2.2037 14.0027 1.95508 13.0171 1.95508 12.0037C1.95508 10.9907 2.20356 10.0021 2.67121 9.26951C5.05218 5.55738 8.45904 3.47998 12.0001 3.47998C15.5413 3.47998 18.948 5.55756 21.3187 9.26913L21.3194 9.27023C21.7865 9.99724 22.0351 10.9828 22.0351 11.9962C22.0351 13.0095 21.7865 13.9982 21.3187 14.7309C18.9479 18.4424 15.5413 20.52 12.0001 20.52C8.44863 20.52 5.04205 18.4422 2.67146 14.7308ZM12.0001 3.97998C8.55682 3.97998 5.32506 6.04702 3.09877 9.54072C2.65861 10.2277 2.45758 11.1313 2.45758 12C2.45758 12.8684 2.6585 13.7718 3.0984 14.4587C5.3247 17.9527 8.55663 20.02 12.0001 20.02C15.4433 20.02 18.6751 17.9529 20.9014 14.4592C21.3415 13.7723 21.5426 12.8686 21.5426 12C21.5426 11.1312 21.3415 10.2274 20.9012 9.54039C18.6749 6.04689 15.4432 3.97998 12.0001 3.97998Z"
                                  fill="#042E60"
                                  stroke="#042E60"
                                />
                              </svg>
                            )}
                          </span>
                          <input
                            type={type}
                            className="form-control"
                            id="exampleInputPassword1"
                            placeholder="New Password"
                            onChange={(e) => validate(e.target.value)}
                          // value={newPassword}
                          />
                          {errorMessage === "" ? null : (
                            <span
                              style={{
                                fontWeight: "bold",
                                color:
                                  errorMessage == "Is Strong Password"
                                    ? "green"
                                    : "red",
                                fontSize: "14px",
                              }}
                            >
                              {errorMessage}
                            </span>
                          )}
                        </div>
                        <div className="form-group grp-1" id="passwordInput">
                          <label for="confirmPassword1" className="form-labels">
                            {LoginString.ResetCP}
                          </label>
                          <span className="eyevector">
                            {type1 === "password" ? (
                              // <RiEyeOffLine
                              //   style={{ fontSize: "25px" }}
                              //   onClick={() => handleShowHide1("text")}
                              // />
                              <button
                                style={{ fontSize: "25px" }}
                                onClick={() => handleShowHide1("text")}
                              >show</button>
                            ) : (
                              <svg
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                                onClick={() => handleShowHide1("password")}
                              >
                                <path
                                  d="M11.9999 16.3299C9.60992 16.3299 7.66992 14.3899 7.66992 11.9999C7.66992 9.60992 9.60992 7.66992 11.9999 7.66992C14.3899 7.66992 16.3299 9.60992 16.3299 11.9999C16.3299 14.3899 14.3899 16.3299 11.9999 16.3299ZM11.9999 9.16992C10.4399 9.16992 9.16992 10.4399 9.16992 11.9999C9.16992 13.5599 10.4399 14.8299 11.9999 14.8299C13.5599 14.8299 14.8299 13.5599 14.8299 11.9999C14.8299 10.4399 13.5599 9.16992 11.9999 9.16992Z"
                                  fill="#042E60"
                                />
                                <path
                                  d="M2.67146 14.7308L2.67075 14.7297C2.2037 14.0027 1.95508 13.0171 1.95508 12.0037C1.95508 10.9907 2.20356 10.0021 2.67121 9.26951C5.05218 5.55738 8.45904 3.47998 12.0001 3.47998C15.5413 3.47998 18.948 5.55756 21.3187 9.26913L21.3194 9.27023C21.7865 9.99724 22.0351 10.9828 22.0351 11.9962C22.0351 13.0095 21.7865 13.9982 21.3187 14.7309C18.9479 18.4424 15.5413 20.52 12.0001 20.52C8.44863 20.52 5.04205 18.4422 2.67146 14.7308ZM12.0001 3.97998C8.55682 3.97998 5.32506 6.04702 3.09877 9.54072C2.65861 10.2277 2.45758 11.1313 2.45758 12C2.45758 12.8684 2.6585 13.7718 3.0984 14.4587C5.3247 17.9527 8.55663 20.02 12.0001 20.02C15.4433 20.02 18.6751 17.9529 20.9014 14.4592C21.3415 13.7723 21.5426 12.8686 21.5426 12C21.5426 11.1312 21.3415 10.2274 20.9012 9.54039C18.6749 6.04689 15.4432 3.97998 12.0001 3.97998Z"
                                  fill="#042E60"
                                  stroke="#042E60"
                                />
                              </svg>
                            )}
                          </span>
                          <input
                            type={type1}
                            className="form-control"
                            id="confirmPassword1"
                            placeholder="Confirm Password"
                            onChange={(e) => validateConfirm(e.target.value)}
                          // value={confirmPassword}
                          />
                          {errorMessage01 === "" ? null : (
                            <span
                              style={{
                                fontWeight: "bold",
                                color:
                                  errorMessage01 == "Confirm Password Same"
                                    ? "green"
                                    : "red",
                                fontSize: "14px",
                              }}
                            >
                              {errorMessage01}
                            </span>
                          )}
                        </div>

                        {/* <p className="pswrd-desc">{LoginString.Regpswdesc}</p> */}
                      </form>
                    </div>

                    <div className="Register-Buttons pt-5 pb-5 text-center">
                      <button
                        type="button"
                        className="rgster-btn"
                        onClick={handleSubmit}
                      >
                        {btnLoadStatus ? (
                          <small className="spinner-border spinner-border-sm"></small>
                        ) : (
                          LoginString.ForgotSubmit
                        )}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
      <ToastContainer
        position="top-right"
        autoClose={3000}
        limit={1}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
    </div>
  );
};
export default VerifyAccount;
