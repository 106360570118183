import { combineReducers } from "redux";
import { bookingData } from "./BookingData";

const LoginData = (state = {}, action) => {
  switch (action.type) {
    case "addUserData":
      return {
        id: action.payload.id,
        first_name: action.payload.first_name,
        last_name: action.payload.last_name,
        avatar: action.payload.avatar,
        email: action.payload.email,
        country_code: action.payload.country_code,
        phone_number: action.payload.phone_number,
        wallet_balance: action.payload.wallet_balance,
        password: action.payload.password,

      };
    case "updateUserData":
      return {
        ...state,
        first_name: action.payload.first_name,
        last_name: action.payload.last_name,
        password: action.payload.password,

      };
    case "updateUserImage":
      return {
        ...state,
        avatar: action.payload.avatar,
      };

    case "Logout":
      return {};

    default:
      return state;
  }
};
const HomeData = (state = {}, action) => {
  switch (action.type) {
    case "appsettings":
      return {
        ...state,
        appsettings: action.payload,
      };
    default:
      return state;
  }
};
const socialLoginData = (state = {}, action) => {
  switch (action.type) {
    case "addSocialLoginData":
      return {
        ...state,
        socialLoginData: action.payload,
      };
    case "clearSocialData":
      return {};
    default:
      return state;
  }
};
const Currentbooking = (state = {}, action) => {
  switch (action.type) {
    case "addtocurrent":
      const data = action.payload;
      return data;

    case "clearcurrent":
      return {};
    default:
      return state;
  }
};
const historyTrip = (state = {}, action) => {
  switch (action.type) {
    case "addtohistory":
      const data = action.payload;
      return data;

    case "clearhistory":
      return {};
    default:
      return state;
  }
};
const callReciever = (state = false, action) => {
  switch (action.type) {
    case "trigger":
      return action.payload;

    case "offtrigger":
      return false;
    default:
      return state;
  }
};
const ChatReciever = (state = {
  open: false,
  driver_id: 18,
  bookingID: 361
}, action) => {
  switch (action.type) {
    case "push_appear":
      const all_value = action.payload;
      return {
        ...state, ...all_value
      };

    case "push_disappear":
      return {
        ...state, ...{
          open: false,
          driver_id: null,
          bookingID: null
        }
      };
    default:
      return state;
  }
};
const jobType = (state = 1, action) => {
  switch (action.type) {
    case "setType":
      return action.payload;

    case "offtrigger":
      return false;
    default:
      return state;
  }
};
const IGReducer = combineReducers({
  LgnData: LoginData,
  HomeData,
  socialLoginData,
  booking: bookingData,
  Currentbooking,
  historyTrip,
  callReciever,
  jobType,
  ChatReciever
});

export default IGReducer;
